export default {
  data() {
    return {
      categories: [],
      parents: {},
      search: '',
      parts: []
    }
  },
  computed: {
    filteredList() {
      return this.categories.filter(option => {
        return option.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    selectedList() {
      return this.categories.filter(option => {
        return option.selected
      })
    }
  },
  watch: {
    selectedList: function(array) {
      this.parts = array.map(option => {
        return option.id
      })
    }
  },
  mounted() {
    axios.get('/api/categories').then(res => {
      this.parents = res.data
    })

    axios.post('/api/categories', { q: '' }).then(res => {
      let categories = [],
        seen = {}
      categories = res.data.map(item => {
        item.selected = false
        return item
      })
      categories.sort(function(a, b) {
        if (a.name === 'Diverse') {
          return 1
        } else if (a.name < b.name) {
          return -1
        } else if (a.name > b.name) {
          return 1
        }
        return 0
      })
      this.categories = categories
    })
  },
  methods: {
    toggle: function(option) {
      option.selected = !option.selected
    },
    remove: function(option) {
      option.selected = false
    }
  }
}
