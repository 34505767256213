export default {
    "en": {
        "widgets": {
            "hero": {
                "looking_for": "I'm looking for..."
            }
        },
        "forms": {
            "contact": {
                "display_name": "Contact form",
                "verify": "Verification image",
                "description": "AutoPartsSite is not a selling party and only has a mediating function and therefore does not trade auto parts.\n                          For more information you must contact the provider of the part.\n                          If you want to request a demo for a paid account, if you have a (technical) question or a complaint,\n                          please complete the form below. We will contact you shortly.",
                "paid_description": "If you have a (technical) question or a complaint, or if you would like to list a new component\n                               Please complete the form below. We will contact you shortly."
            },
            "dashboard": {
                "language": "Taal",
                "languageLabel": "Taalkeuze is optioneel (wanneer we later meetalig gaan)",
                "currentPassword": "Huidig wachtwoord",
                "newPassword": "Nieuw wachtwoord",
                "repeatPassword": "Herhaal nieuw wachtwoord",
                "changePassword": "Wijzig je wachtwoord",
                "changePasswordButton": "Wachtwoord wijzigen",
                "changeEmail": "E­mailadres wijzigen",
                "currentEmail": "info@test.com",
                "newEmail": "Nieuw e­mailadres",
                "repeatEmail": "Herhaal nieuw e­mailadres",
                "save": "Opslaan",
                "deleteAccount": "Verwijder mijn account",
                "agreeToDelete": "Verwijder al mijn gegevens en aanvraaghistorie"
            },
            "onboarding": {
                "type": "Type",
                "address": "Street",
                "accountHolder": "Account holder",
                "affiliatedWith": "Affiliated with",
                "bic": "BIC",
                "city": "City",
                "coc_nr": "Chamber of Commerce nr.",
                "comment": "Post your comment here",
                "companyName": "Company name",
                "contact": "Contact us",
                "copy": "Do you want to receive a copy?",
                "copyConfirm": "Yes send me a copy",
                "country": "Country",
                "disassembly": "Disassembly",
                "email": "Email",
                "emailConfirmation": "Confirm E-mail",
                "firstName": "First name",
                "garageCompany": "Garagebedrijf",
                "building_nr": "House nr",
                "iban": "IBAN",
                "lastName": "Last name",
                "message": "Post your message here",
                "mobile": "Mobile",
                "phone": "Telephone",
                "postal": "Postalcode",
                "province": "Province",
                "revisionCompany": "Revision company",
                "salutation": "Salutation",
                "selectProvince": "Select Province",
                "street": "Street",
                "streetname": "Streetname",
                "termsAndConditions": "I agree with the general terms and conditions.",
                "title": "Complete your account",
                "vat_nr": "VAT nr",
                "website": "Website",
                "review_and_activate": "We will review your account and activate it once approved.",
                "creation_finished": "Account creation finished.",
                "upload": "Upload the logo of your company"
            },
            "registration": {
                "free_account": "I want to search for parts(Free account)",
                "seller_account": "I want to sell parts (payed account)",
                "select": "Select your account type",
                "verify": "Verify your email address",
                "request": "click here to request another",
                "validation": "Thank you for registering with Autoonderdelensite.nl. Before you can log in you must activate your\n                         account. Check your e-mail box for the activation link. Don't you\n                         receive e-mail, first check your SPAM box"
            },
            "request": {
                "addrow": "Add Row",
                "gear": "Gear Box",
                "gear_val_1": "4 bin",
                "gear_val_2": "5 bin",
                "gear_val_3": "6 bin",
                "gear_val_4": "Automatic Overdrive",
                "gear_val_5": "DSG",
                "gear_val_6": "Semi-Automatic",
                "address": "Address",
                "articleNumber": "Article number",
                "bodyTitle1": "Which car part do you need?",
                "bodyTitle2": "Parts",
                "bodyTitle3": "Contact details",
                "chassisNumber": "Chassis number",
                "comment": "Post your comment here",
                "company": "Company Name",
                "component": "Component",
                "confirmEmail": "Confirm e-mail",
                "constructionMonth": "Construction month",
                "constructionYear": "Construction year",
                "country": "Land",
                "createAccount": "Do you want to create an account right away?",
                "doors": "Doors",
                "body": "Body",
                "drive": "Drive",
                "email": "Email",
                "emailAddress": "e-Mail address",
                "engineType": "Engine type",
                "fueltype": "fuel Type",
                "houseNo": "HouseNr.",
                "make": "Brand",
                "minRequired": "at least one field is required",
                "mobile": "Mobile",
                "model": "Model",
                "nextStep2": "To step 2",
                "nextStep3": "To step 3",
                "no": "No",
                "performance": "Performance",
                "phone": "Telephone",
                "place": "Place",
                "postcode": "Postal",
                "power": "Power (kW)",
                "province": "Province",
                "optional": "(optional)",
                "reciveVia": "I want to receive responses via",
                "requiredFields": "field is required",
                "retrievePlate": "retrieve license plate information",
                "sendCopy": " Do you want to receive a copy of this request via e-mail?",
                "sendMeCopy": "Yes, send me a copy",
                "sendRequest": "Send request",
                "sms": "SMS",
                "step1": "Step 1",
                "step2": "Step 2",
                "step3": "Step 3",
                "lastStep": "Success",
                "thanks": "Thank you!",
                "homepage": "Home page",
                "stepConfirmation": "Your Request has been successfully created. Click the button to go back to home page.",
                "subtitle1": "If you don't have a license plate? Then enter the car information manually",
                "subtitle3": "Enter your contact details here.",
                "type": "Motor Type",
                "aldoc-type": "Aldoc Type",
                "title": "Which car part do you need?",
                "Vehicledata": "Vehicle data",
                "workInAutoIndustry": "Do you work in the automotive industry?",
                "yes": "yes",
                "send": "Send",
                "description_step_1": "Submit your application for free and quickly.\n                                 Always check the (license plate) data and add it where it's necessary.\n                                 The more information you enter, the more easier for the supplier to find the right parts for your car.",
                "description_step_2": "Enter the car parts you are looking for here. Use the suggestion table for faster processing of your request.\n                                 If you have the item number, you can enter it in the corresponding field.\n                                  Use the explanation rule for additional information and add photos if available.",
                "description_step_3": "Enter your personal data details as complete as possible. The companies can understand your request more faster.",
                "warning_step_1": "Always make sure to check the data and edit when necessary."
            }
        },
        "validation": {
            "accepted": "The {attribute} must be accepted.",
            "active_url": "The {attribute} is not a valid URL.",
            "after": "The {attribute} must be a date after {date}.",
            "after_or_equal": "The {attribute} must be a date after or equal to {date}.",
            "alpha": "The {attribute} may only contain letters.",
            "alpha_dash": "The {attribute} may only contain letters, numbers, and dashes.",
            "alpha_num": "The {attribute} may only contain letters and numbers.",
            "array": "The {attribute} must be an array.",
            "before": "The {attribute} must be a date before {date}.",
            "before_or_equal": "The {attribute} must be a date before or equal to {date}.",
            "between": {
                "numeric": "The {attribute} must be between {min} and {max}.",
                "file": "The {attribute} must be between {min} and {max} kilobytes.",
                "string": "The {attribute} must be between {min} and {max} characters.",
                "array": "The {attribute} must have between {min} and {max} items."
            },
            "boolean": "The {attribute} field must be true or false.",
            "captcha": "Incorrect image verification.",
            "confirmed": "The {attribute} confirmation does not match.",
            "date": "The {attribute} is not a valid date.",
            "date_format": "The {attribute} does not match the format {format}.",
            "different": "The {attribute} and {other} must be different.",
            "digits": "The {attribute} must be {digits} digits.",
            "digits_between": "The {attribute} must be between {min} and {max} digits.",
            "dimensions": "The {attribute} has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "email": "The {attribute} must be a valid email address.",
            "exists": "The selected {attribute} is invalid.",
            "file": "The {attribute} must be a file.",
            "filled": "The {attribute} field must have a value.",
            "image": "The {attribute} must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field does not exist in {other}.",
            "integer": "The {attribute} must be an integer.",
            "ip": "The {attribute} must be a valid IP address.",
            "ipv4": "The {attribute} must be a valid IPv4 address.",
            "ipv6": "The {attribute} must be a valid IPv6 address.",
            "json": "The {attribute} must be a valid JSON string.",
            "max": {
                "numeric": "The {attribute} may not be greater than {max}.",
                "file": "The {attribute} may not be greater than {max} kilobytes.",
                "string": "The {attribute} may not be greater than {max} characters.",
                "array": "The {attribute} may not have more than {max} items."
            },
            "mimes": "The {attribute} must be a file of type: {values}.",
            "mimetypes": "The {attribute} must be a file of type: {values}.",
            "min": {
                "numeric": "The {attribute} must be at least {min}.",
                "file": "The {attribute} must be at least {min} kilobytes.",
                "string": "The {attribute} must be at least {min} characters.",
                "array": "The {attribute} must have at least {min} items."
            },
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} format is invalid.",
            "numeric": "The {attribute} must be a number.",
            "present": "The {attribute} field must be present.",
            "regex": "The {attribute} format is invalid.",
            "required": "The {attribute} field is required.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} is present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} and {other} must match.",
            "size": {
                "numeric": "The {attribute} must be {size}.",
                "file": "The {attribute} must be {size} kilobytes.",
                "string": "The {attribute} must be {size} characters.",
                "array": "The {attribute} must contain {size} items."
            },
            "string": "The {attribute} must be a string.",
            "timezone": "The {attribute} must be a valid zone.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "url": "The {attribute} format is invalid.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "account_type": "Account type",
                "address": "Address",
                "age": "Age",
                "city": "City",
                "company": "Company",
                "country": "Country",
                "day": "Day",
                "display_name": "Display name",
                "email": "Email",
                "first_name": "Firstname",
                "free": "Free",
                "gender": "Gender",
                "last_name": "Lastname",
                "lastname": "Lastname",
                "mobile": "Mobile",
                "name": "Name",
                "new_password": "New password",
                "new_password_confirmation": "Confirm new password",
                "old_password": "Old password",
                "password": "Password",
                "password_confirmation": "Confirm password",
                "phone": "Phone",
                "postal_code": "Postal code",
                "seller": "Seller",
                "street": "Street",
                "user": "User",
                "username": "Pseudo",
                "sex": "Sex",
                "month": "Month",
                "year": "Year",
                "hour": "Hour",
                "minute": "Minute",
                "second": "Second",
                "title": "Title",
                "content": "Content",
                "description": "Description",
                "summary": "Summary",
                "excerpt": "Excerpt",
                "date": "Date",
                "time": "Time",
                "available": "Available",
                "size": "Size",
                "roles": "Roles",
                "permissions": "Permissions",
                "active": "Active",
                "message": "Message",
                "locale": "Localization",
                "route": "Route",
                "url": "URL alias",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Recipients",
                "source_path": "Original path",
                "bic": "BIC number",
                "body": "Body",
                "building_nr": "Number",
                "coc_nr": "CC number",
                "company_name": "Company name",
                "featured": "Featured",
                "features": "Features",
                "image": "Image",
                "images": "Images",
                "listings": "Listings",
                "messages": "Messages",
                "metable_type": "Entity",
                "monthly_price": "Monthly price",
                "order": "Display order",
                "period": "Period",
                "pinned": "Pinned",
                "price": "Price",
                "promoted": "Promoted",
                "published_at": "Publish at",
                "redirect_type": "Redirect type",
                "status": "Status",
                "tags": "Tags",
                "target_path": "Target path",
                "timezone": "Timezone",
                "topic": "Topic",
                "type": "Type",
                "unpublished_at": "Unpublish at",
                "vat_nr": "VAT number",
                "website": "Website"
            },
            "requestparts": {
                "car": {
                    "lp_notfound": "Licenseplate not found."
                },
                "parts": {
                    "cat": {
                        "main": "The main category at part {nr} is required.",
                        "sub": "The subcategory at part {nr} is required."
                    },
                    "generic": "Invalid input received."
                }
            }
        },
        "routes": {
            "index": "index",
            "about": "about",
            "catalog": "catalog",
            "companies": "companies",
            "completed": "completed",
            "confirmation": "confirmation",
            "contact": "contact",
            "contact-sent": "contact-sent",
            "disclaimer": "disclaimer",
            "links": "links",
            "listing": "listing",
            "parts": "parts",
            "redactors": "content/redactors/{user}",
            "request": "request",
            "search": "search",
            "signoff": "signoff",
            "account": {
                "register": "register"
            }
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "socialite": {
                "unacceptable": "{provider} is not an acceptable login type."
            }
        },
        "labels": {
            "language": "Language",
            "actions": "Actions",
            "general": "General",
            "no_results": "No results available",
            "search": "Search",
            "validate": "Validate",
            "choose_file": "Choose File",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Are you sure ?",
            "delete_image": "Delete image",
            "yes": "Yes",
            "no": "No",
            "add_new": "Add",
            "export": "Export",
            "more_info": "More info",
            "author": "Author",
            "author_id": "Author ID",
            "last_access_at": "Last access at",
            "published_at": "Published at",
            "created_at": "Created at",
            "updated_at": "Updated at",
            "deleted_at": "Deleted at",
            "no_value": "No value",
            "upload_image": "Upload image",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "fields_required": "Fields are required",
            "success": "Car successfully added.",
            "update": "Car successfully updated.",
            "no_lpn_change": "Can not change license plate number once filled in. Add a new car instead.",
            "no_chassis_change": "Can not change chassis number once filled in. Add a new car instead.",
            "no_delete": "Can not delete car since there are still parts connected to this car.",
            "deleted": "Car deleted.",
            "account": {
                "titles": {
                    "register": "Register",
                    "hello": "Hello",
                    "dashboard": "You are in your account dashboard",
                    "myrequest": "My requests",
                    "mysettings": "My settings"
                },
                "cars": {
                    "location": "Location",
                    "Unknown": "Unknown",
                    "km_position": "KMs",
                    "date_of_purchase": "Purchase date",
                    "apk_valid_until": "APK valid untill",
                    "none": "None",
                    "save_car": "Save car",
                    "delete_car": "Remove car",
                    "edit_car": "Edit car",
                    "add_car": "Add car",
                    "no_car": "There is no car found please press the button to add a new car",
                    "selectBrand": "Select Brand",
                    "selectModel": "Select Model",
                    "color": "Color",
                    "coating_nr": "Coating number",
                    "engine_code": "Engine code",
                    "ktype": "K-type",
                    "gearbox_type": "Gearbox type",
                    "gearbox_code": "Gearbox code"
                },
                "create": {
                    "add_a_car": "Add a car",
                    "select_car": "Select donor car",
                    "purchase": "Purchase price",
                    "months": "maanden",
                    "component_condition": "Part condition",
                    "new": "New",
                    "used": "Used",
                    "article_number": "Article Number",
                    "quantity_in_stock": "Quantity in stock",
                    "selling_price": "Selling price",
                    "price_on_request": "Price on request",
                    "sales_tax": "Tax sales",
                    "marge": "Show margin",
                    "part_name": "Part name",
                    "warranty": "Warranty",
                    "location": "Location",
                    "description": "Description",
                    "internal": "(internal)",
                    "save": "Save",
                    "show_item": "Show part on platform",
                    "selectCategory": "Select Category",
                    "selectSubCategory": "Select SubCategory",
                    "cat": "Category",
                    "subcat": "Name"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\" alt=\"logout\">{admin}</a>."
            },
            "auth": {
                "disabled": "Your account has been disabled."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "cars": {
                    "titles": {
                        "main": "Cars",
                        "index": "Cars list",
                        "create": "Create car",
                        "edit": "Edit car",
                        "delete": "Delete car"
                    },
                    "actions": {
                        "destroy": "Delete selected cars",
                        "enable": "Enable selected cars",
                        "disable": "Disable selected cars"
                    }
                },
                "categories": {
                    "titles": {
                        "main": "Categories",
                        "index": "Category list",
                        "create": "Create category",
                        "edit": "Edit category"
                    },
                    "actions": {
                        "destroy": "Delete selected categories",
                        "enable": "Enable selected categories",
                        "disable": "Disable selected categories"
                    }
                },
                "companies": {
                    "titles": {
                        "main": "Companies",
                        "index": "Company list",
                        "create": "Create company",
                        "edit": "Edit company"
                    },
                    "actions": {
                        "destroy": "Delete selected companies",
                        "enable": "Enable selected companies",
                        "disable": "Disable selected companies"
                    }
                },
                "forms": {
                    "titles": {
                        "main": "Forms",
                        "index": "Form list",
                        "create": "Form creation",
                        "edit": "Form modification"
                    },
                    "descriptions": {
                        "recipients": "Example: 'webmaster@example.com' or 'sales@example.com,support@example.com' . To specify multiple recipients, separate each email address with a comma.",
                        "message": "The message to display to the user after submission of this form. Leave blank for no message."
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Submission",
                        "index": "Submission list",
                        "show": "Submission detail"
                    },
                    "actions": {
                        "destroy": "Delete selected submissions"
                    }
                },
                "grouped_models": {
                    "titles": {
                        "add_model": "Add {model} to model group",
                        "create": "Create new grouped model for {model}"
                    },
                    "forms": {
                        "dropdown_label": "Model groups"
                    }
                },
                "listings": {
                    "titles": {
                        "main": "Listings",
                        "index": "Listings",
                        "create": "Create listing",
                        "edit": "Edit listing"
                    },
                    "actions": {
                        "destroy": "Delete selected listings",
                        "enable": "Enable selected listings",
                        "disable": "Disable selected listings"
                    }
                },
                "new_menu": {
                    "company": "New company",
                    "form": "New form",
                    "user": "New user",
                    "role": "New role"
                },
                "parts": {
                    "titles": {
                        "main": "Parts",
                        "index": "Parts list",
                        "create": "Create part",
                        "edit": "Edit part",
                        "delete": "Delete part"
                    },
                    "actions": {
                        "destroy": "Delete selected parts",
                        "enable": "Enable selected parts",
                        "disable": "Disable selected parts"
                    }
                },
                "pricing_models": {
                    "titles": {
                        "main": "Pricing models",
                        "index": "Pricing models",
                        "create": "Create pricing model",
                        "edit": "Edit pricing model",
                        "delete": "Delete pricing model"
                    },
                    "actions": {
                        "destroy": "Delete selected pricing model",
                        "enable": "Enable selected pricing model",
                        "disable": "Disable selected pricing model"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Roles",
                        "index": "Role list",
                        "create": "Role creation",
                        "edit": "Role modification"
                    }
                },
                "sidebar": {
                    "access": "Access",
                    "content": "Content",
                    "forms": "Forms",
                    "seo": "SEO",
                    "site": "Site"
                },
                "titles": {
                    "dashboard": "Dashboard"
                },
                "users": {
                    "titles": {
                        "main": "Users",
                        "index": "User list",
                        "create": "User creation",
                        "edit": "User modification"
                    },
                    "actions": {
                        "destroy": "Delete selected users",
                        "enable": "Enable selected users",
                        "disable": "Disable selected users"
                    }
                }
            },
            "cookieconsent": {
                "message": "This website uses cookies to ensure you get the best experience on our website.",
                "dismiss": "Got it !"
            },
            "datatables": {
                "no_results": "No results available",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "http": {
                "403": {
                    "title": "Access Denied",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Page Not Found",
                    "description": "Sorry, but the page you were trying to view does not exist."
                },
                "500": {
                    "title": "Server Error",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "English",
                "nl": "Dutch"
            },
            "morphs": {
                "post": "Post, identity {id}",
                "user": "User, identity {id}"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "user": {
                "dashboard": "Dashboard",
                "addresses": "Addresses",
                "remember": "Remember",
                "login": "Login",
                "logout": "Logout",
                "password_forgot": "Forgot password ?",
                "send_password_link": "Forgot password",
                "password_reset": "Password Reset",
                "register": "Register",
                "settings": "Settings",
                "account": "Account",
                "profile": "Profile",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Edit profile",
                "change_password": "Change password",
                "delete": "Delete account",
                "backend": "Backend",
                "member_since": "Member since {date}",
                "profile_updated": "Profile successfully updated.",
                "password_updated": "Password successfully updated.",
                "super_admin": "Super administrator",
                "loginHeader": "Login",
                "registerHeader": "Register",
                "benefits": "Benefits of an account",
                "benefitsText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a elementum enim. Proin luctus tellus et mauris luctus, quis sagittis felis gravida. Etiam non sollicitudin ex. In quis justo ac orci posuere rhoncus.",
                "resetPassword": "Enter your email address to receive a password reset link.",
                "resetInfo": "You will receive a link in your mailbox. Use this link to enter a new password for your Autoonderdelensite.nl account.",
                "send": "Send",
                "description_1": "Make a request faster. Your data will be entered automatically.",
                "description_2": "Access to previously placed applications.",
                "description_3": "Reviewing responses to all your requests.",
                "description_4": "Easier relocation and cancellation of applications.",
                "account_delete": "This action will delete entirely your account from this site as well as all associated data.",
                "account_deleted": "Account successfully deleted",
                "titles": {
                    "dashboard": "Dashboard",
                    "account": "Account"
                }
            },
            "frontend": {
                "titles": {
                    "index": "Index",
                    "home": "Home",
                    "about": "About us",
                    "backend": "Backend",
                    "category": "Category",
                    "categories": "Categories",
                    "cms": "Content",
                    "contact": "Ask us your question",
                    "disclaimer": "Disclaimer",
                    "links": "Links",
                    "message_sent": "Message sent",
                    "privacy": "Privacy",
                    "cookies": "Cookies",
                    "condition": "Conditions",
                    "sitemap": "Sitemap",
                    "browse": "Browse",
                    "catalog": "Catalog",
                    "rules": "Rules",
                    "catalogSearch": "Catalog Search",
                    "listing": "Listings",
                    "company": "Company",
                    "request": "Request",
                    "comment": "Opmerking",
                    "part": "part",
                    "requestCancellation": "Request cancellation",
                    "info": "Information",
                    "createCompanyAcc": "Create company account",
                    "demo": "Request a demo",
                    "faq": "FAQ",
                    "followUs": "Follow us on",
                    "selectBrand": "Search<br/> auto part<br/> per brand",
                    "viewBrands": "View all brands",
                    "description": "Description",
                    "copyright": "&copy; Copyright - AutoOnderdelenSite.nl 2023 - realized by rmic",
                    "block_1": "Here you will find an overview of all your applications.",
                    "block_2": "Here you can manage your own data. Tip: if you enter your details as completely as possible, this will save you a little more in the application.",
                    "block_3": "Don't forget to log out at the top when you leave the website.",
                    "welcome": "Welcome to your dashboard In this overview you can arrange a number of things yourself. This way you have an overview of all your requests.\n             You can manage your own data and log out immediately."
                },
                "home": {
                    "enterPlate": "Enter your license plate",
                    "doYouHavePlate": "If you don't have a dutch license plate? Then select a car brand manually",
                    "requestButton": "Request for free",
                    "chooseCarBrand": "Or select a car brand",
                    "partRequest": "CREATE A REQUEST FOR CAR PARTS",
                    "partsStock": "FIND YOUR CAR PARTS STOCK",
                    "participatingCompanies": "PARTICIPATING COMPANIES",
                    "placeForUSP": "HERE IS SPACE FOR A USP",
                    "requestLastPart": "Latest parts",
                    "autoPartsSite": "Auto Parts Site scores an average of",
                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis mauris sed erat convallis, quis vehicula justo fringilla. Quisque a libero sem. Suspendisse ullamcorper leo sit amet ante vestibulum sagittis.",
                    "3weeksAgo": "3 weeks ago",
                    "contactPhone": "Tel 085 - 222 18 97",
                    "mostSearched": "Most searched brands",
                    "doYouHaveQuestion": "Do you have a technical question or do you need help with your request?",
                    "orToGo": "OR GO TO",
                    "AutoPartsSite": "Auto Parts Site",
                    "inBrief": "Shortly",
                    "ourBenefits": "Our benefits",
                    "piston": "PLACE YOUR PARTS FOR FREE AND FREE FOR ALL REQUESTS",
                    "profile": "CREATE A FREE ACCOUNT QUICKLY AND EASILY (NOT REQUIRED)",
                    "car": "LARGE DATABASE OF BRANDS AND TYPE OF CARS"
                },
                "request": {
                    "addFile": "Add file",
                    "upload": "click the below button to upload an image less than 4MB",
                    "upload_logo": "Upload your company logo",
                    "introduction": "Write a public introduction (shown on the company's profile page)",
                    "articleNumber": "Article Number",
                    "approachByTelephone": "Contact by telephone",
                    "approachByEmail": "Contact by email",
                    "bumperRed": "Bumper red ",
                    "carDetails": "Car details",
                    "chassis": "Chassis nr",
                    "company": "From which company you buy the part?",
                    "confirm": "Confirm your request",
                    "construction": "Construction",
                    "contact": "Contact details",
                    "copy": "A copy of your request will be sent to you by email for reference.",
                    "createAccount": "Create account",
                    "description": "Wanneer je alle gevraagde onderdelen of een deel van de aanvraag aan de klant gaat leveren en er is een overeenkomst kan je de deal sluiten. De aanvrager krijgt vervolgens een bestelbevestiging. Betaling en facturatie geschiet echter buiten Auto Onderdelen Site.",
                    "directionIndicator": "Richtingaanwijzer",
                    "doors": "Doors",
                    "drive": "Drive",
                    "email": "E-mail",
                    "explanation": "Explanation (optional)",
                    "fuelType": "Fuel type",
                    "image": "Supporting extensions: jpg, png. Maximum file size 4MB.",
                    "job": " Work in car industry",
                    "maxSize": "Photos, max 2 MB",
                    "message": "Message",
                    "noResult": "Nothing found please use an other searchterm",
                    "mobile": "Mobile",
                    "part": "Part",
                    "productID": "product ID",
                    "partsSold": "Parts sold",
                    "password": "Password: The password you have chosen",
                    "phone": "Phone",
                    "power": "Power",
                    "price": "What price have you agreed for this part?",
                    "requested": "Requested part(s) details",
                    "response": "Receive responses via:",
                    "save": "Confirm",
                    "score": "What score do you give this company?",
                    "selectCategory": "Select category",
                    "selectFile": "Select file",
                    "send": "Submit",
                    "shockAbsorber": "Schock absorber",
                    "subCategory": "Select category",
                    "selectLabel": "Press enter to select",
                    "selectOption": "Select Option(s)",
                    "deselectLabel": "Press enter to remove",
                    "selectCatergoryDropdown": "Select a category",
                    "selectSubCatergoryDropdown": "Choose a part",
                    "totalAmount": "Total deliverable parts",
                    "size": "Your image size is ",
                    "denySize": "MB bigger then 4 MB, please upload smaller size image ",
                    "sizeSuccess": "Your image uploaded successfully.",
                    "uploaded": "Uploaded image size is"
                },
                "listings": {
                    "modelPhoto": "Model Photo",
                    "make": "Make",
                    "model": "Model",
                    "invalide_code": " Invalid sign-off code",
                    "no_car": "There is no car found please press the button to add a new car",
                    "no_listings": "There are no listings found",
                    "performance": "Performance",
                    "year": "Year",
                    "date": "Date",
                    "part": "Part",
                    "marge": "(marge)",
                    "delete_message": "Part listing successfully deleted.",
                    "region": "Region",
                    "photo": "Photo",
                    "addCarPart": "Add car Part",
                    "addDonorCar": "Add donor car",
                    "add": "Add",
                    "donorCar": "Donor car",
                    "instruction": "Instruction",
                    "video": "Videos",
                    "teamviewer": "Teamviewer",
                    "help": "Help",
                    "settings": "Settings",
                    "licensePlate": "License Plate",
                    "purchase": "Purchase Date",
                    "action": "Action",
                    "no_account": "The account has been removed",
                    "delete": "Delete",
                    "results": "Result found",
                    "grid": "Grid",
                    "list": "List",
                    "market": "Search marketplace",
                    "search": "Search",
                    "recent": "Recent",
                    "low_high": "price low to high",
                    "high_low": "price high to low",
                    "near": "nearest first",
                    "category": "Categories",
                    "posted": "Posted",
                    "by": "created by",
                    "own": "My requests",
                    "other": "Other requests",
                    "done": "Completed requests",
                    "clear": "Clear",
                    "perPage": "Per Page",
                    "supplier": "Supplier",
                    "requester": "Requester",
                    "status": "Status",
                    "actions": "Actions",
                    "city": "City",
                    "aldoc_model": "Aldoc Model",
                    "state": "State",
                    "success_part": "Part listing successfully created.",
                    "open": "Open",
                    "complete": "Complete",
                    "to_car": "To Car",
                    "goback": "To requests",
                    "edit": "Edit Listings",
                    "delete_button_part": "Delete",
                    "delete_button_request": "Sign out",
                    "success_mail_request": "Your message has been send to the owner of this listing."
                },
                "catalogue": {
                    "title": "Find your car part",
                    "licensePlate": "License Plate",
                    "fill": "Fill in the number plate",
                    "brand": "Brand",
                    "articleNumber": "ARTICLE NUMBER",
                    "enterTheLicensePlate": "Enter the license plate number",
                    "selectcarbrand": "Select a brand",
                    "findResults": "Find Results",
                    "search": "Search",
                    "searchby": "Search by license plate number",
                    "selectBrandModel": "Select the Alfa Romeo Giulietta car part that you are looking for",
                    "description": "Below you can search in different ways.\n            If you search by license plate you will find all parts for that particular car. Don't have a license plate?\n            Then search specifically for a part via, for example, the brand, article number or engine code."
                },
                "filters": {
                    "companyType": "Company type",
                    "dismantling": "Dismantling companies",
                    "garage": "Garage companies",
                    "revision": "Revision companies",
                    "subscription": "Subscription",
                    "country": "Country",
                    "companyName": "Company name",
                    "postcode": "Postal",
                    "ray": "Ray",
                    "clear": "Clear all filters",
                    "update": "Update results"
                },
                "help": {
                    "question": "Hulp nodig? 085 - 222 18 97"
                },
                "overview": {
                    "part_name": "Part name",
                    "description": "Description",
                    "price": "Price",
                    "make": "Make",
                    "model": "Model",
                    "overview": "Overview",
                    "type": "Motor type",
                    "year": "Year",
                    "art_num": "Article number",
                    "category": "Category",
                    "ship_cost": "Shipping Costs",
                    "deposit": "Deposit",
                    "power": "Power: ",
                    "garantie_months": "Warranty months",
                    "garantie_description": "Warranty description",
                    "condition": "Condition",
                    "location": "Location",
                    "particularity_one": "Particularity 1",
                    "particularity_two": "Particularity 2",
                    "shipping": "Verzendkosten NL",
                    "shipping_ext": "Verzendkosten buiten NL"
                },
                "companies": {
                    "stock": "Parts stock",
                    "moreInfo": "More information",
                    "goTo": "Go to website",
                    "demontage": "Demontage",
                    "results": "Results found",
                    "search": "type to search",
                    "clear": "clear",
                    "name": "Name *",
                    "company": "Company *",
                    "phone": "Telephone *",
                    "email": "Email address *",
                    "message": "Post your message here",
                    "contact": "Please contact",
                    "back": "Back to overview",
                    "contact_via": "Contact this provider via:",
                    "more_info": "For more information:",
                    "color": "Color",
                    "paint_number": "Paint number",
                    "motorcode": "Motorcode",
                    "k-type": "K-type",
                    "gearbox_type": "Gearbox type",
                    "gearbox_code": "Gearbox code"
                },
                "submissions": {
                    "message_sent": "<p>Your message has been successfully sent</p>"
                },
                "placeholders": {
                    "locale": "Select your language",
                    "timezone": "Select your timezone"
                },
                "brands": {
                    "more": "More brands ⮟"
                },
                "cms": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "tags": "Tags"
                }
            }
        },
        "permissions": {
            "access": {
                "backend": {
                    "display_name": "Backend access",
                    "description": "Can access to backend pages."
                }
            },
            "categories": {
                "access": "Access",
                "catalog": "Catalog",
                "categories": "Categories",
                "cms": "Content",
                "companies": "Companies",
                "content": "Content",
                "form": "Forms",
                "listing": "Listings"
            },
            "create": {
                "cars": {
                    "display_name": "Create cars",
                    "description": "Can create cars."
                },
                "categories": {
                    "display_name": "Create categories",
                    "description": "Can create categories."
                },
                "companies": {
                    "display_name": "Create companies",
                    "description": "Can create companies."
                },
                "forms": {
                    "display_name": "Create forms",
                    "description": "Can create forms."
                },
                "parts": {
                    "display_name": "Create parts",
                    "description": "Can create parts."
                },
                "roles": {
                    "display_name": "Create roles",
                    "description": "Can create roles."
                },
                "users": {
                    "display_name": "Create users",
                    "description": "Can create users."
                }
            },
            "delete": {
                "cars": {
                    "display_name": "Delete cars",
                    "description": "Can delete cars."
                },
                "companies": {
                    "display_name": "Delete companies",
                    "description": "Can delete companies."
                },
                "forms": {
                    "display_name": "Delete forms",
                    "description": "Can delete forms."
                },
                "form_submissions": {
                    "display_name": "Delete form submissions",
                    "description": "Can delete form submissions."
                },
                "parts": {
                    "display_name": "Delete parts",
                    "description": "Can delete parts."
                },
                "roles": {
                    "display_name": "Delete roles",
                    "description": "Can delete roles."
                },
                "users": {
                    "display_name": "Delete users",
                    "description": "Can delete users."
                }
            },
            "disable": {
                "listing": {
                    "display_name": "Disable listing",
                    "description": "Can disable listing."
                }
            },
            "edit": {
                "cars": {
                    "display_name": "Edit cars",
                    "description": "Can edit cars."
                },
                "categories": {
                    "display_name": "Edit categories",
                    "description": "Can edit categories."
                },
                "companies": {
                    "display_name": "Edit companies",
                    "description": "Can edit companies."
                },
                "forms": {
                    "display_name": "Edit forms",
                    "description": "Can edit forms."
                },
                "listing": {
                    "display_name": "Edit listing",
                    "description": "Can edit listing."
                },
                "parts": {
                    "display_name": "Edit parts",
                    "description": "Can edit parts."
                },
                "roles": {
                    "display_name": "Edit roles",
                    "description": "Can edit roles."
                },
                "users": {
                    "display_name": "Edit users",
                    "description": "Can edit users."
                }
            },
            "impersonate": {
                "display_name": "Impersonate user",
                "description": "Can take ownership of others user identities. Useful for tests."
            },
            "publish": {
                "listing": {
                    "display_name": "Publish listing",
                    "description": "Can publish listing."
                },
                "posts": {
                    "display_name": "Publish posts",
                    "description": "Can manage posts publication."
                }
            },
            "unpublish": {
                "listing": {
                    "display_name": "Unpublish listing",
                    "description": "Can unpublish listing."
                }
            },
            "view": {
                "cars": {
                    "display_name": "View cars",
                    "description": "Can view cars."
                },
                "categories": {
                    "display_name": "View categories",
                    "description": "Can view categories."
                },
                "companies": {
                    "display_name": "View companies",
                    "description": "Can view companies."
                },
                "forms": {
                    "display_name": "View forms",
                    "description": "Can view forms."
                },
                "form_submissions": {
                    "display_name": "View form submissions",
                    "description": "Can view form submissions."
                },
                "parts": {
                    "display_name": "View parts",
                    "description": "Can view parts."
                },
                "roles": {
                    "display_name": "View roles",
                    "description": "Can view roles."
                },
                "users": {
                    "display_name": "View users",
                    "description": "Can view users."
                }
            }
        },
        "passwords": {
            "password": "Passwords must be at least six characters and match the confirmation.",
            "reset": "Your password has been reset!",
            "sent": "We have e-mailed your password reset link!",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that e-mail address."
        },
        "buttons": {
            "apply": "Apply",
            "back": "Back",
            "cancel": "Cancel",
            "close": "Close",
            "confirm": "Confirm",
            "create": "Create",
            "delete": "Delete",
            "edit": "Edit",
            "login-as": "Login as {name}",
            "preview": "Preview",
            "save": "Save",
            "send": "Send",
            "show": "Show",
            "update": "Update",
            "view": "View",
            "companies": {
                "create": "Create company"
            },
            "forms": {
                "create": "Create form"
            },
            "metas": {
                "create": "Create meta"
            },
            "plans": {
                "main": "Plans",
                "create": "Create plan"
            },
            "posts": {
                "create": "Create post",
                "save_and_publish": "Save and publish",
                "save_as_draft": "Save as draft"
            },
            "roles": {
                "create": "Create role"
            },
            "redirects": {
                "create": "Create redirect",
                "import": "Import CSV"
            },
            "subscriptions": {
                "create": "Create subscription"
            },
            "users": {
                "create": "Create user"
            },
            "grouped_models": {
                "create": "Create grouped model"
            }
        },
        "exceptions": {
            "general": "Server exception",
            "unauthorized": "Action not allowed",
            "backend": {
                "users": {
                    "create": "Error on user creation",
                    "update": "Error on user updating",
                    "delete": "Error on user deletion",
                    "user_cannot_be_edited": "You cannot edit this user",
                    "user_cannot_be_disabled": "This user cannot be disabled",
                    "user_cannot_be_destroyed": "This user cannot be deleted",
                    "user_cannot_be_impersonated": "This user cannot be impersonated",
                    "cannot_set_superior_roles": "You cannot attribute roles superior to yours"
                },
                "roles": {
                    "create": "Error on role creation",
                    "update": "Error on role updating",
                    "delete": "Error on role deletion"
                },
                "metas": {
                    "create": "Error on meta creation",
                    "update": "Error on meta updating",
                    "delete": "Error on meta deletion",
                    "already_exist": "There is already a meta for this locale route"
                },
                "form_submissions": {
                    "create": "Error on submission creation",
                    "delete": "Error on submission deletion"
                },
                "forms": {
                    "create": "Error on form creation",
                    "update": "Error on form updating",
                    "delete": "Error on form deletion",
                    "already_exist": "This form already exists"
                },
                "redirects": {
                    "create": "Error on redirect creation",
                    "update": "Error on redirect updating",
                    "delete": "Error on redirect deletion",
                    "already_exist": "There is already a redirect for this path"
                },
                "posts": {
                    "create": "Error on post creation",
                    "update": "Error on post updating",
                    "save": "Error on post saving",
                    "delete": "Error on post deletion"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "That e-mail address is already taken.",
                    "password_mismatch": "That is not your old password.",
                    "delete_account": "Error on account deletion.",
                    "updating_disabled": "Account editing is disabled."
                },
                "auth": {
                    "registration_disabled": "Registration is disabled."
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "User ID {user} created",
                    "updated": "User ID {user} updated",
                    "deleted": "User ID {user} deleted"
                },
                "form_submissions": {
                    "created": "Form submission ID {form_submission} created"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Dear AutoPartsSite user",
                "regards": "Regards",
                "trouble": "If you’re having trouble clicking the {action} button, copy and paste the URL below into your web browser :",
                "all_rights_reserved": "All rights reserved.",
                "website": "AutoOnderdelenSite",
                "description": "AutoPartsSite is an online platform for finding the right buyer / seller of used car\n                    components. We only have an intermediary function and are not a party to any agreement\n                    between the buyer and seller. We accept no liability whatsoever for whatever reason. This\n                    applies, but not exclusively, to the accuracy of the information, the delivery, the delivery period,\n                    prices and warranty conditions of parts offered by automotive connected to our platform\n                    dismantling companies.\n                    The warranty and delivery conditions, as well as shipping costs, can differ per part and per company.\n                    The prices of the offered car parts can differ greatly. We advise you on this\n                    to inform the seller in advance.\n\n"
            },
            "password_reset": {
                "subject": "Password reset",
                "intro": "Click the button to reset your password: ",
                "website": "Follow the instructions via the website",
                "action": "Reset Password",
                "outro": "The button above does not work? Then copy and paste this URL into your web browser:",
                "contact": "Do you have any questions? click ",
                "in": " us"
            },
            "contact": {
                "subject": "New contact message",
                "copy": "Copy of your message",
                "new_contact": "You've got a new contact message. Submission detail :",
                "greetings": "Hello",
                "reaction": "There is a response to your part request:",
                "content": "This is the message from: ",
                "contact": "Contact details",
                "offer": "OFFER",
                "door": "Door",
                "link": "Do you have any questions? Contact us via",
                "salutation": "Sincerely,",
                "app": "Autoonderdelensite,",
                "category": "Category: ",
                "email": "E-mail address: ",
                "phone": "Telephone number: ",
                "company": "Company Name: ",
                "address": "Address: ",
                "website": "Website: ",
                "carInfo": "YOUR SPECIFIED VEHICLE DATA",
                "info": "Use the above information to contact us for more information or about the possible purchase of the part."
            },
            "alert": {
                "subject": "[{app_name}] Exception error",
                "message": "You've got unexpected server exception error which message is : {message}.",
                "trace": "All trace detail :"
            },
            "confirmation": {
                "request": "Your request has been delivered. We will be processing it as quickly as possible.",
                "data": "YOUR SPECIFIED VEHICLE DATA",
                "lp": "License Plate:",
                "model": "Model:",
                "type": "Type:",
                "year": "Construction year:",
                "cylinder": "Cylinder capacity:",
                "parts": "PARTS REQUESTED BY YOU",
                "category": "Category",
                "description": "Description",
                "code": "Log out code",
                "contact": "YOUR CONTACT INFORMATION",
                "name": "Name:",
                "place": "Location:",
                "form": "Contact Form"
            },
            "activation": {
                "subject": "Activation link for AutoOnderdelenSite.nl account",
                "link": "Activation link",
                "activate": "You can activate your account by clicking on the link below: ",
                "contact": "If that doesn't work, copy the link below into your own browser. ",
                "content": "Dear user, Thank you for your free registration with Auto Parts Site.nl.\n                        Before you can log in, your account need to be activated."
            },
            "account": {
                "verify": {
                    "subject": "Verify email address"
                }
            },
            "listing": {
                "reminder": {
                    "subject": "Reminder parts request {sitename}"
                }
            },
            "request": {
                "confirmation": {
                    "subject": "Request confirmation"
                }
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "listings": {
            "back_to": "Back to listing",
            "back_to_my": "Back to my listings",
            "listing": "Listing",
            "listings": "Listings",
            "no_results": "No results found."
        },
        "subscriptions": {
            "subscribe_to_plan": "Subscribe to a plan"
        },
        "alerts": {
            "backend": {
                "actions": {
                    "invalid": "Invalid action"
                },
                "companies": {
                    "created": "Company created",
                    "updated": "Company updated",
                    "deleted": "Company deleted"
                },
                "form_submissions": {
                    "deleted": "Submission deleted",
                    "bulk_destroyed": "Selected submissions deleted"
                },
                "forms": {
                    "created": "Form created",
                    "updated": "Form updated",
                    "deleted": "Form deleted"
                },
                "metas": {
                    "created": "Meta created",
                    "updated": "Meta updated",
                    "deleted": "Meta deleted",
                    "bulk_destroyed": "Selected metas deleted"
                },
                "posts": {
                    "created": "Post created",
                    "updated": "Post updated",
                    "deleted": "Post deleted",
                    "bulk_destroyed": "Selected posts deleted",
                    "bulk_published": "Selected posts published",
                    "bulk_pending": "Selected posts are awaiting moderation",
                    "bulk_pinned": "Selected posts pinned",
                    "bulk_promoted": "Selected posts promoted"
                },
                "redirects": {
                    "created": "Redirect created",
                    "updated": "Redirect updated",
                    "deleted": "Redirect deleted",
                    "bulk_destroyed": "Selected redirects deleted",
                    "bulk_enabled": "Selected redirects enabled",
                    "bulk_disabled": "Selected redirects disabled",
                    "file_imported": "File successfully imported"
                },
                "roles": {
                    "created": "Role created",
                    "updated": "Role updated",
                    "deleted": "Role deleted"
                },
                "users": {
                    "created": "User created",
                    "updated": "User updated",
                    "deleted": "User deleted",
                    "bulk_destroyed": "Selected users deleted",
                    "bulk_enabled": "Selected users enabled",
                    "bulk_disabled": "Selected users disabled"
                }
            },
            "frontend": []
        }
    },
    "nl": {
        "widgets": {
            "hero": {
                "looking_for": "Ik ben op zoek naar..."
            }
        },
        "forms": {
            "contact": {
                "display_name": "Contact form",
                "verify": "Afbeelding verificatie *",
                "description": "AutoOnderdelenSite is geen verkopende partij en heeft alleen een bemiddelende functie en\n                          verhandeld zelf dus geen auto-onderdelen.\n                          Voor meer informatie moet je contact opnemen met de aanbieder van het onderdeel.\n                          Wil je een demo aanvragen voor een betaald account,\n                          heb je een (technische) vraag of een klacht vul dan onderstaand formulier in. We nemen dan spoedig contact met je op.",
                "paid_description": "Heb je een technische vraag, wilt u een nieuw onderdeel toevoegen of heeft u een klacht?\n                               Laat het ons weten via onderstaande formulier en nemen dan spoedig contact met u op."
            },
            "dashboard": {
                "language": "Taal",
                "languageLabel": "Taalkeuze is optioneel (wanneer we later meetalig gaan)",
                "currentPassword": "Huidig wachtwoord",
                "newPassword": "Nieuw wachtwoord",
                "repeatPassword": "Herhaal nieuw wachtwoord",
                "changePassword": "Wijzig je wachtwoord",
                "changePasswordButton": "Wachtwoord wijzigen",
                "changeEmail": "E­mailadres wijzigen",
                "currentEmail": "info@test.com",
                "newEmail": "Nieuw e­mailadres",
                "repeatEmail": "Herhaal nieuw e­mailadres",
                "save": "Opslaan",
                "deleteAccount": "Verwijder mijn account",
                "agreeToDelete": "Verwijder al mijn gegevens en aanvraaghistorie"
            },
            "onboarding": {
                "address": "Straat",
                "type": "Type",
                "accountHolder": "Rekeninghouder",
                "affiliatedWith": "Aangesloten bij",
                "bic": "BIC",
                "city": "Plaats",
                "coc_nr": "KVK nummer",
                "comment": "Plaats hier je opmerking",
                "companyName": "Bedrijfsnaam",
                "contact": "Reageren op deze aanvraag",
                "copy": "Wil je een kopie ontvangen?",
                "copyConfirm": "Ja stuur mij een kopie",
                "country": "Land",
                "disassembly": "Demontage",
                "email": "Email",
                "emailConfirmation": "Email bevestigen",
                "firstName": "Voornaam",
                "garageCompany": "Garagebedrijf",
                "building_nr": "HuisNr.",
                "iban": "IBAN",
                "lastName": "Achternaam",
                "message": "Uw bericht",
                "mobile": "Mobiel telefoonnummer",
                "phone": "Telefoon",
                "postal": "Postcode",
                "province": "Provincie",
                "revisionCompany": "Revisiebedrijf",
                "salutation": "Aanhef",
                "selectProvince": "Selecteer provincie",
                "street": "Straat",
                "streetname": "Straatnaam",
                "termsAndConditions": "ik ga akkoord met de algemene voorwaarden.",
                "title": "Maak je account compleet",
                "vat_nr": "BTW nummer",
                "website": "Website",
                "review_and_activate": "We gaan uw gegevens verifiëren en activeren zodra deze zijn goedgekeurd.",
                "creation_finished": "Account aanmaken afgerond.",
                "upload": "Upload het logo van je bedrijf"
            },
            "registration": {
                "free_account": "Ik wil onderdelen zoeken (gratis account)",
                "seller_account": "Ik wil onderdelen verkopen (betaald account)",
                "select": "Selecteer uw accounttype",
                "verify": "Verifieer je e-mailadres",
                "request": "klik hier om een ​​andere aan te vragen",
                "validation": "Dank voor jouw registratie bij Autoonderdelensite.nl. Voordat je kan inloggen moet jouw\n                         account nog geactiveerd worden. Controleer je e-mail box voor de activatielink. Heb je geen\n                         e-mail ontvangen, controleer dan eerst je SPAM box"
            },
            "request": {
                "address": "Adres",
                "gear": "Versnellingsbak",
                "gear_val_1": "4 Bak",
                "gear_val_2": "5 Bak",
                "gear_val_3": "6 Bak",
                "gear_val_4": "Automaat",
                "gear_val_5": "DSG",
                "gear_val_6": "Semi-Automaat",
                "addrow": "Toevoegen onderdeel",
                "articleNumber": "Artikelnummer",
                "bodyTitle1": "Auto-onderdeel aanvraag plaatsen",
                "bodyTitle2": "Onderdelen",
                "bodyTitle3": "Contactgegevens",
                "chassisNumber": "Chassisnummer",
                "comment": "Plaats hier je opmerking",
                "company": "Bedrijfsnaam",
                "component": "Onderdeel",
                "confirmEmail": "E-mailadres bevestigen",
                "constructionMonth": "Bouwmaand",
                "constructionYear": "Bouwjaar",
                "country": "Land",
                "createAccount": "Wil je direct een account aanmaken?",
                "doors": "Deuren",
                "body": "Carosserie",
                "drive": "Aandrijving",
                "email": "E-mailadres",
                "emailAddress": "E-mailadres",
                "engineType": "Motortype",
                "fueltype": "Brandstoftype",
                "houseNo": "Huisnr.",
                "make": "Merk",
                "minRequired": "minimaal één veld is verplicht",
                "mobile": "Mobiel",
                "model": "Model",
                "nextStep2": "Naar stap 2",
                "nextStep3": "Naar stap 3",
                "no": "Nee",
                "performance": "Motortype",
                "phone": "Telefoon",
                "place": "Plaats",
                "postcode": "Postcode",
                "power": "Vermogen (kW)",
                "province": "Provincie",
                "optional": "(optioneel)",
                "reciveVia": "Ik wil reacties ontvangen via",
                "requiredFields": "veld is verplicht",
                "retrievePlate": "kentekengegevens ophalen",
                "sendCopy": " Wil je een kopie van deze aanvraag ontvangen via e-mail?",
                "sendMeCopy": "Ja, stuur mij een kopie",
                "sendRequest": "Aanvraag versturen",
                "sms": "SMS",
                "step1": "Stap 1",
                "step2": "Stap 2",
                "step3": "Stap 3",
                "lastStep": "Aanvraag verzonden",
                "thanks": "Bedankt!",
                "homepage": "Homepagina",
                "stepConfirmation": "Je aanvraag is succesvol verzonden. Klik op de button om terug te keren naar de homepagina.",
                "subtitle1": "Heb je geen kenteken? Voer dan handmatig de voertuiggegevens in.",
                "subtitle3": "Vul hier uw contactgegevens in.",
                "title": "Welk auto-onderdeel heb je nodig?",
                "type": "Motor Type",
                "aldoc-type": "Aldoc Type",
                "workInAutoIndustry": "Werk je in de automotive branche?",
                "yes": "Ja",
                "send": "Versturen",
                "Vehicledata": "Voertuiggegevens",
                "question": "    Heb je een technische vraag of\n            heb je hulp nodig bij je aanvraag?",
                "description_step_1": "Gratis en snel je aanvraag plaatsen.\n                                 Controleer altijd de (kenteken)gegevens en vul deze daar waar nodig aan.\n                                 Hoe meer informatie je invult, hoe makkelijker het voor de leverancier is om de juiste onderdelen voor jouw auto op te zoeken.",
                "description_step_2": "Voer hier de auto-onderdelen in die je zoekt. Maak gebruik van de suggestietabel voor een snellere verwerking van jouw aanvraag.\n                                 Indien je beschikt over het artikelnummer kan je deze opgegeven in het bijbehorende veld.\n                                  Gebruik de toelichtingregel voor aanvullende informatie en voeg indien beschikbaar foto's toe.",
                "description_step_3": "Vul jouw contactgevens zo compleet mogelijk in. De bedrijven kunnen je dan sneller benaderen.",
                "warning_step_1": "Controleer altijd de gegevens en vul deze daar waar nodig aan."
            }
        },
        "validation": {
            "accepted": "{attribute} dient te worden geaccepteerd.",
            "active_url": "{attribute} is geen geldige URL.",
            "after": "{attribute} dient een datum te zijn na {date}.",
            "alpha": "{attribute} mag alleen letters bevatten.",
            "alpha_dash": "{attribute} mag alleen letters, nummers, and strepen bevatten.",
            "alpha_num": "{attribute} mag alleen letters en nummers bevatten.",
            "array": "{attribute} moet een array zijn.",
            "before": "{attribute} moet een datum zijn eerder dan {date}.",
            "between": {
                "numeric": "{attribute} moet tussen {min} en {max} liggen.",
                "file": "{attribute} moet tussen {min} en {max} kilobytes zijn.",
                "string": "{attribute} moet tussen {min} en {max} karakters lang zijn.",
                "array": "{attribute} moet tussen {min} en {max} items bevatten."
            },
            "boolean": "{attribute} kan enkel true of false zijn.",
            "captcha": "Incorrecte afbeeldings verificatie.",
            "confirmed": "{attribute} bevestiging komt niet overeen.",
            "date": "{attribute} is geen geldige datum.",
            "date_format": "{attribute} komt niet overeen met het formaat {format}.",
            "different": "{attribute} en {other} dienen verschillend te zijn.",
            "digits": "{attribute} moet {digits} cijfers zijn.",
            "digits_between": "{attribute} moet tussen {min} en {max} cijfers zijn.",
            "email": "{attribute} dient een geldig emailadres te zijn.",
            "filled": "{attribute} veld is verplicht.",
            "exists": "Het geselecteerde {attribute} is ongeldig.",
            "image": "{attribute} dient een afbeelding te zijn.",
            "in": "Het geselecteerde {attribute} is ongeldig.",
            "integer": "{attribute} dient een geheel getal te zijn.",
            "ip": "{attribute} dient een geldig IP adres te zijn.",
            "max": {
                "numeric": "{attribute} mag niet groter zijn dan {max}.",
                "file": "{attribute} mag niet groter zijn dan {max} kilobytes.",
                "string": "{attribute} mag niet groter zijn dan {max} karakters.",
                "array": "{attribute} mag niet meer dan {max} items bevatten."
            },
            "mimes": "{attribute} dient een bestand te zijn van het type: {values}.",
            "min": {
                "numeric": "{attribute} dient minimaal {min} te zijn.",
                "file": "{attribute} dient minimaal {min} kilobytes te zijn.",
                "string": "{attribute} dient minimaal {min} karakters te bevatten.",
                "array": "{attribute} dient minimaal {min} items te bevatten."
            },
            "not_in": "Het geselecteerde {attribute} is ongeldig.",
            "numeric": "Het {attribute} dient een nummer te zijn.",
            "regex": "Het {attribute} formaat is ongeldig.",
            "required": "Het {attribute} veld is verplicht.",
            "required_if": "Het {attribute} veld is verplicht wanneer {other} is {value}.",
            "required_with": "Het {attribute} veld is verplicht wanneer {values} aanwezig is.",
            "required_with_all": "Het {attribute} veld is verplicht wanneer {values} aanwezig is.",
            "required_without": "Het {attribute} veld is verplicht wanneer {values} niet aanwezig is.",
            "required_without_all": "Het {attribute} veld is verplicht wanneer geen van {values} aanwezig is.",
            "same": "Het {attribute} en {other} moeten hetzelfde zijn.",
            "size": {
                "numeric": "{attribute} moet {size} zijn.",
                "file": "{attribute} moet {size} kilobytes groot zijn.",
                "string": "{attribute} moet {size} karakters lang zijn.",
                "array": "{attribute} moet {size} items bevatten."
            },
            "unique": "{attribute} is al bezet.",
            "url": "{attribute} formaat is ongeldig.",
            "timezone": "{attribute} moet een geldige tijdszone zijn.",
            "custom": {
                "attribute-name": {
                    "rule-name": "custom-message"
                }
            },
            "attributes": {
                "account_type": "Account type",
                "address": "Adres",
                "age": "Leeftijd",
                "city": "Plaats",
                "content": "Inhoud",
                "company": "Bedrijf",
                "country": "Land",
                "day": "Dag",
                "date": "Datum",
                "description": "Beschrijving",
                "display_name": "Weergave naam",
                "email": "E-mailadres",
                "excerpt": "Excerpt",
                "first_name": "Voornaam",
                "free": "Gratis",
                "gender": "Geslacht",
                "hour": "Uur",
                "last_name": "Achternaam",
                "lastname": "Achternaam",
                "minute": "Minuut",
                "mobile": "Mobiel",
                "month": "Maand",
                "name": "Naam",
                "parts": "Onderdelen",
                "new_password": "Nieuw wachtwoord",
                "new_password_confirmation": "Bevestig nieuw wachtwoord",
                "old_password": "Huidig wachtwoord",
                "password": "Wachtwoord",
                "password_confirmation": "Bevestig wachtwoord",
                "phone": "Telefoon",
                "postal_code": "Postcode",
                "street": "Straat",
                "second": "Seconde",
                "seller": "Verkoper",
                "sex": "Sex",
                "title": "Titel",
                "user": "Gebruiker",
                "username": "Pseudo",
                "year": "Jaar",
                "summary": "Samenvatting",
                "time": "Tijd",
                "available": "Beschikbaar",
                "size": "Size",
                "roles": "Rollen",
                "permissions": "Permissies",
                "active": "Actief",
                "message": "Bericht",
                "locale": "Taal",
                "route": "Route",
                "url": "URL alias",
                "form_type": "Form type",
                "form_data": "Form data",
                "recipients": "Ontvangers",
                "bic": "BIC nummer",
                "body": "Body",
                "building_nr": "Nummer",
                "coc_nr": "KvK nummer",
                "company_name": "Bedrijfsnaam",
                "featured": "Aanbevolen",
                "features": "Features",
                "image": "Afbeelding",
                "images": "Afbeeldingen",
                "listings": "Listings",
                "messages": "Berichten",
                "metable_type": "Entity",
                "monthly_price": "Maand prijs",
                "order": "Display order",
                "period": "Period",
                "pinned": "Pinned",
                "price": "Prijs",
                "promoted": "Promoted",
                "published_at": "Publish at",
                "redirect_type": "Redirect type",
                "source_path": "Original path",
                "status": "Status",
                "tags": "Tags",
                "target_path": "Target path",
                "timezone": "Tijdzone",
                "topic": "Onderwerp",
                "type": "Type",
                "unpublished_at": "Unpublish at",
                "vat_nr": "BTW nummer",
                "website": "Website"
            },
            "requestparts": {
                "car": {
                    "lp_notfound": "Kenteken niet gevonden."
                },
                "parts": {
                    "cat": {
                        "main": "De hoofd categorie voor onderdeel {nr} is verplicht.",
                        "sub": "De sub categorie voor onderdeel {nr} is verplicht."
                    },
                    "generic": "Ongeldige data ontvangen."
                }
            }
        },
        "routes": {
            "index": "index",
            "about": "over",
            "catalog": "catalogus",
            "companies": "bedrijven",
            "completed": "afgerond",
            "confirmation": "bevestiging",
            "contact": "contact",
            "contact-sent": "contact-verstuurd",
            "disclaimer": "disclaimer",
            "links": "links",
            "listing": "listing",
            "parts": "onderdelen",
            "redactors": "content/redacteurs/{user}",
            "request": "aanvraag",
            "search": "zoeken",
            "signoff": "afmelden",
            "account": {
                "register": "registreer"
            }
        },
        "auth": {
            "failed": "De verstuurde gegevens komen niet overeen met onze gegevens.",
            "throttle": "Te veel login pogingen. Probeer het nogmaals over {seconds} secondes."
        },
        "labels": {
            "language": "Taal",
            "actions": "Acties",
            "general": "Algemeen",
            "no_results": "Geen resultaten beschikbaar",
            "search": "Search",
            "validate": "Valideer",
            "choose_file": "Kies bestand",
            "no_file_chosen": "No file chosen",
            "are_you_sure": "Weet u het zeker?",
            "delete_image": "Verwijder afbeelding",
            "yes": "Ja",
            "no": "Nee",
            "add_new": "Add",
            "export": "Exporteer",
            "more_info": "Meer info",
            "author": "Auteur",
            "author_id": "Auteur ID",
            "last_access_at": "Recentste toegang op",
            "published_at": "Gepubliceerd op",
            "created_at": "Aangemaakt op",
            "updated_at": "Bijgewerkt op",
            "deleted_at": "Verwijderd op",
            "no_value": "Geen waarde",
            "upload_image": "Foto's aanpassen",
            "anonymous": "Anonymous",
            "all_rights_reserved": "All rights reserved.",
            "with": "with",
            "by": "by",
            "fields_required": "veld is verplicht",
            "success": "Auto succesvol toegevoegd.",
            "update": "Auto succesvol bijgewerkt.",
            "no_lpn_change": "Het is niet mogelijk een kenteken aan te passen. Voeg een nieuwe auto toe.",
            "no_chassis_change": "Het is niet mogelijk het chassisnummer aan te passen. Voeg een nieuwe auto toe.",
            "no_delete": "Kan auto niet verwijderen, omdat er onderdelen aan deze auto zijn gekoppeld.",
            "deleted": "Auto verwijderd.",
            "account": {
                "titles": {
                    "register": "Registreer",
                    "hello": "Hallo",
                    "dashboard": "je bevind je in het dashboard",
                    "myrequest": "Beheer",
                    "mysettings": "Mijn instellingen"
                },
                "cars": {
                    "location": "Locatie",
                    "Unknown": "Onbekend",
                    "km_position": "KM Stand",
                    "date_of_purchase": "Aankoopdatum",
                    "apk_valid_until": "APK geldig tot",
                    "none": "Geen",
                    "save_car": "Auto opslaan",
                    "delete_cars": "Auto verwijderen",
                    "edit_car": "Auto bewerken",
                    "add_car": "Auto toevoegen",
                    "no_car": "Er is geen auto gevonden. Klik op de button hieronder om een auto toe te voegen.",
                    "selectBrand": "Selecteer merk",
                    "selectModel": "Selecteer model",
                    "color": "Kleur",
                    "coating_nr": "Laknummer",
                    "engine_code": "Motorcode",
                    "ktype": "K-type",
                    "gearbox_type": "Type versnellingbak",
                    "gearbox_code": "Versnellingsbakcode"
                },
                "create": {
                    "add_a_car": "Voeg een auto toe",
                    "months": "maanden",
                    "select_car": "Selecteer donorauto",
                    "purchase": "Inkoopprijs",
                    "component_condition": "Onderdeel conditie",
                    "new": "Nieuw",
                    "used": "Gebruikt",
                    "article_number": "Artikelnummer",
                    "quantity_in_stock": "Aantal op voorraad",
                    "selling_price": "Verkoopprijs",
                    "stock": "Voorraad",
                    "price_on_request": "Prijs tonen",
                    "sales_tax": "BTW verkoop",
                    "marge": "Marge tonen",
                    "part_name": "Onderdeel naam",
                    "warranty": "Garantie",
                    "location": "locatie",
                    "description": "Omschrijving",
                    "internal": "(intern)",
                    "save": "Opslaan",
                    "show_item": "Toon onderdeel op platform",
                    "selectCategory": " Selecteer categorie",
                    "selectSubCategory": " Selecteer subcategorie",
                    "cat": "Categorie",
                    "subcat": "Naam"
                }
            },
            "alerts": {
                "login_as": "You are actually logged as <strong>{name}</strong>, you can logout as <a href=\"{route}\" data-turbolinks=\"false\" alt=\"logout\">{admin}</a>."
            },
            "auth": {
                "disabled": "Uw account is uitgeschakeld."
            },
            "backend": {
                "dashboard": {
                    "new_posts": "New posts",
                    "pending_posts": "Pending posts",
                    "published_posts": "Published posts",
                    "active_users": "Active users",
                    "form_submissions": "Submissions",
                    "last_posts": "Last posts",
                    "last_published_posts": "Last publications",
                    "last_pending_posts": "Last pending posts",
                    "last_new_posts": "Last new posts",
                    "all_posts": "All posts"
                },
                "cars": {
                    "titles": {
                        "main": "Cars",
                        "index": "Cars list",
                        "create": "Create car",
                        "edit": "Edit car",
                        "delete": "Delete car"
                    },
                    "actions": {
                        "destroy": "Delete selected cars",
                        "enable": "Enable selected cars",
                        "disable": "Disable selected cars"
                    }
                },
                "categories": {
                    "titles": {
                        "main": "Categorieën",
                        "index": "Categorieën lijst",
                        "create": "Categorie aanmaken",
                        "edit": "Categorie bewerken"
                    }
                },
                "companies": {
                    "titles": {
                        "main": "Bedrijven",
                        "index": "Bedrijven lijst",
                        "create": "Bedrijf aanmaken",
                        "edit": "Bedrijf bewerken"
                    },
                    "actions": {
                        "destroy": "Verwijder geselecteerde bedrijven",
                        "enable": "Activeer geselecteerde bedrijven",
                        "disable": "Deactiveer geselecteerde bedrijven"
                    }
                },
                "forms": {
                    "titles": {
                        "main": "Forms",
                        "index": "Form list",
                        "create": "Form creation",
                        "edit": "Form modification"
                    },
                    "descriptions": {
                        "recipients": "Example: 'webmaster@example.com' or 'sales@example.com,support@example.com' . To specify multiple recipients, separate each email address with a comma.",
                        "message": "The message to display to the user after submission of this form. Leave blank for no message."
                    }
                },
                "form_submissions": {
                    "titles": {
                        "main": "Submission",
                        "index": "Submission list",
                        "show": "Submission detail"
                    },
                    "actions": {
                        "destroy": "Delete selected submissions"
                    }
                },
                "grouped_models": {
                    "titles": {
                        "add_model": "{model} toevoegen aan modelgroep",
                        "create": "Nieuw grouped model aanmaken voor {model}"
                    },
                    "forms": {
                        "dropdown_label": "Modelgroepen"
                    }
                },
                "new_menu": {
                    "company": "New company",
                    "form": "New form",
                    "user": "New user",
                    "role": "New role"
                },
                "listings": {
                    "titles": {
                        "main": "Listings",
                        "index": "Listings",
                        "create": "Create listing",
                        "edit": "Edit listing",
                        "delete": "Delete listing"
                    },
                    "actions": {
                        "destroy": "Delete selected listings",
                        "enable": "Enable selected listings",
                        "disable": "Disable selected listings"
                    }
                },
                "parts": {
                    "titles": {
                        "main": "Parts",
                        "index": "Parts list",
                        "create": "Create part",
                        "edit": "Edit part",
                        "delete": "Delete part"
                    },
                    "actions": {
                        "destroy": "Delete selected parts",
                        "enable": "Enable selected parts",
                        "disable": "Disable selected parts"
                    }
                },
                "pricing_models": {
                    "titles": {
                        "main": "Pricing models",
                        "index": "Pricing models",
                        "create": "Create pricing model",
                        "edit": "Edit pricing model",
                        "delete": "Delete pricing model"
                    },
                    "actions": {
                        "destroy": "Delete selected pricing model",
                        "enable": "Enable selected pricing model",
                        "disable": "Disable selected pricing model"
                    }
                },
                "roles": {
                    "titles": {
                        "main": "Rollen",
                        "index": "Rollen lijst",
                        "create": "Rol aanmaken",
                        "edit": "Rol bewerken"
                    }
                },
                "sidebar": {
                    "content": "Inhoud",
                    "forms": "Formulieren",
                    "access": "Toegang",
                    "seo": "SEO",
                    "site": "Site"
                },
                "titles": {
                    "dashboard": "Dashboard"
                },
                "users": {
                    "titles": {
                        "main": "Gebruikers",
                        "index": "Gebruikers lijst",
                        "create": "Gebruiker aanmaken",
                        "edit": "Gebruiker bewerken"
                    },
                    "actions": {
                        "destroy": "Verijder geselecteerde gebruikers",
                        "enable": "Activeer geselecteerde gebruikers",
                        "disable": "Deactiveer geselecteerde gebruikers"
                    }
                }
            },
            "cookieconsent": {
                "message": "AutoOnderdelenSite gebruikt functionele en analytische cookies en verzamelt daarmee informatie over het gebruik van de website om jou een optimale gebruikerservaring te bieden.",
                "dismiss": "Cookies accepteren!"
            },
            "datatables": {
                "no_results": "Geen resultaten beschikbaar",
                "no_matched_results": "No matched results available",
                "show_per_page": "Show",
                "entries_per_page": "entries per page",
                "search": "Search",
                "infos": "Showing {offset_start} to {offset_end} of {total} entries"
            },
            "descriptions": {
                "allowed_image_types": "Allowed types: png gif jpg jpeg."
            },
            "http": {
                "403": {
                    "title": "Geen toegang",
                    "description": "Sorry, but you do not have permissions to access this page."
                },
                "404": {
                    "title": "Sorry, deze pagina kon niet gevonden worden!",
                    "description": "Mogelijk is de pagina verwijderd omdat de functie niet meer bestaat, of is de url onjuist."
                },
                "500": {
                    "title": "Server fout",
                    "description": "Sorry, but the server has encountered a situation it doesn't know how to handle. We'll fix this as soon as possible."
                }
            },
            "localization": {
                "en": "Engels",
                "nl": "Nederlands"
            },
            "morphs": {
                "post": "Post, identity {id}",
                "user": "User, identity {id}"
            },
            "placeholders": {
                "route": "Select a valid internal route",
                "tags": "Select or create a tag"
            },
            "user": {
                "dashboard": "Dashboard",
                "addresses": "Addressen",
                "remember": "Onthouden",
                "login": "Inloggen",
                "logout": "Uitloggen",
                "password_forgot": "Wachtwoord vergeten?",
                "send_password_link": "Wachtwoord vergeten",
                "password_reset": "Password Reset",
                "register": "Registreren",
                "settings": "Instellingen",
                "account": "Account",
                "profile": "Profiel",
                "avatar": "Avatar",
                "online": "Online",
                "edit_profile": "Profiel aanpassen",
                "change_password": "Wijzig wachtwoord",
                "delete": "Verwijder account",
                "backend": "Backend",
                "member_since": "Account sinds {date}",
                "profile_updated": "Profiel gewijzigd.",
                "password_updated": "Wachtwoord gewijzigd.",
                "super_admin": "Super administrator",
                "loginHeader": "Inloggen",
                "registerHeader": "Registreren",
                "benefits": "De voordelen van een account",
                "benefitsText": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras a elementum enim. Proin luctus tellus et mauris luctus, quis sagittis felis gravida. Etiam non sollicitudin ex. In quis justo ac orci posuere rhoncus.",
                "resetPassword": "Voer je e-mailadres in om je wachtwoord opnieuw in te stellen.",
                "resetInfo": "Je ontvangt vervolgens een link in je mailbox. Gebruik deze link om een nieuw wachtwoord op Autoonderdelensite.nl in te stellen.",
                "send": "Versturen",
                "description_1": "Sneller je aanvraag plaatsen. Je gegevens worden automatisch ingevuld.",
                "description_2": "Toegang tot al eerder geplaatste aanvragen.",
                "description_3": "Het terug kijken van reacties op al je aanvragen.",
                "description_4": "Makkelijker herplaatsen en afmelden van aanvragen.",
                "account_delete": "Het verwijderen van je account zal alle aan de account gekoppelde data verwijderen.",
                "account_deleted": "Account succesvol verwijderd",
                "titles": {
                    "dashboard": "Dashboard",
                    "account": "Account"
                }
            },
            "frontend": {
                "titles": {
                    "index": "Index",
                    "home": "Home",
                    "about": "Over ons",
                    "backend": "Backend",
                    "browse": "Browse",
                    "catalog": "Catalogus",
                    "category": "Categorie",
                    "categories": "Categorieen",
                    "cms": "Content",
                    "condition": "Voorwaarden",
                    "contact": "Contact",
                    "cookies": "Cookies",
                    "createCompanyAcc": "Bedrijfsaccount aanmaken",
                    "disclaimer": "Disclaimer",
                    "links": "Links",
                    "message_sent": "Bericht verstuurd",
                    "privacy": "Privacy",
                    "sitemap": "Sitemap",
                    "rules": "Spelregels",
                    "requestCancellation": "Aanvraag afmelden",
                    "info": "Informatie",
                    "demo": "Demo aanvragen",
                    "followUs": "Volg ons op",
                    "selectBrand": "Zoek op<br/> auto-onderdeel<br/> per merk",
                    "viewBrands": "Bekijk alle merken",
                    "copyright": "&copy; Copyright - AutoOnderdelenSite.nl 2023 - Gerealiseerd door RMIC",
                    "carPartsRequest": "Auto-onderdelen aanvraag",
                    "findYourCarpart": "Zoek je auto-onderdeel",
                    "findResults": "Resultaten zoeken",
                    "articleNumber": "Artikelnummer",
                    "carBrand": "Merk",
                    "model": "Type",
                    "description": "Omschrijving",
                    "company": "Bedrijf",
                    "companies": "bedrijven",
                    "viewPart": "Onderdeel bekijken",
                    "viewRequest": "Bekijk aanvraag",
                    "catalogSearch": "Catalogus zoeken",
                    "request": "Aanvraag",
                    "comment": "Opmerking",
                    "part": "onderdelen",
                    "reactRequest": "Reageren aanvraag",
                    "dataProvider": "Gegevens aanbieder",
                    "details": "Details",
                    "block_1": "Hier kan je aanvragen en auto's en onderdelen inzien en beheren.",
                    "block_2": "Hier kan je je eigen gegevens beheren. Tip: vul je gegevens zo volledig mogelijk in dan scheelt dit weer bij de aanvraag.",
                    "block_3": "Vergeet niet uit te loggen bovenin als je de website verlaat.",
                    "welcome": "Welkom in je dashboard In dit overzicht kan je zelf een aantal dingen regelen. Zo heb je een overzicht van al je aanvragen.\n             Kan je zelf je eigen gegevens beheren en direct uitoggen."
                },
                "home": {
                    "enterPlate": "Start auto-onderdelen aanvraag gratis met jouw kenteken",
                    "doYouHavePlate": "Heb je geen Nederlands kenteken? Selecteer dan handmatig een automerk.",
                    "requestButton": "GRATIS OPVRAGEN",
                    "chooseCarBrand": "OF KIES EEN AUTOMERK",
                    "partRequest": "AUTO-ONDERDELEN AANVRAAG",
                    "partsStock": "AUTO-ONDERDELEN VOORRAAD",
                    "participatingCompanies": "DEELNEMENDE BEDRIJVEN",
                    "placeForUSP": "HIER IS RUIMTE VOOR EEN USP",
                    "requestLastPart": "Laatste onderdelen",
                    "autoPartsSite": "Auto Onderdelen Site  scoort  gemiddeld  een",
                    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sagittis mauris sed erat convallis, quis vehicula justo fringilla. Quisque a libero sem. Suspendisse ullamcorper leo sit amet ante vestibulum sagittis.",
                    "3weeksAgo": "3 weken geleden",
                    "contactPhone": "Bel 085 - 222 18 97",
                    "mostSearched": "Meest gezochte merken",
                    "doYouHaveQuestion": "Heb je een technische vraag of heb je hulp nodig bij je aanvraag?",
                    "orToGo": "OF GA NAAR",
                    "AutoPartsSite": "AutoOnderdelenSite",
                    "inBrief": "in het kort",
                    "ourBenefits": "onze voordelen",
                    "piston": "PLAATS GRATIS EN VRIJBLIJVEND JOUW ONDERDELEN AANVRAAG",
                    "profile": "SNEL EN EENVOUDIG EEN GRATIS ACCOUNT AANMAKEN (NIET VERPLICHT)",
                    "car": "GROTE DATABASE VAN MERKEN EN TYPE AUTO'S",
                    "question": "  Heb je een technische vraag of\n                    heb je hulp nodig bij je aanvraag?"
                },
                "request": {
                    "addFile": "Bestand toevoegen",
                    "upload": "klik op de onderstaande knop om een ​​afbeelding van minder dan 4 MB te uploaden",
                    "upload_logo": "Upload het bedrijfslogo",
                    "introduction": "Schrijf een introductie (zoals publiek getoond op de bedrijfspagina)",
                    "additionalComment": "Aanvullende opmerking klant:",
                    "additionalCommentText": "Nou het moet een goede zijn want de klep is echt helemaal stuk. Het zou prettig zijn als de klep een mooie rubbere rand heeft enzo dit is dus een verhaal.",
                    "approachByEmail": "Per mail benaderen",
                    "approachByTelephone": "Telefonisch benaderen",
                    "articleNumber": "Artikelnummer",
                    "bumperRed": "Bumper rood ",
                    "carDetails": "Auto details",
                    "chassis": "Chassis nr",
                    "company": "Bij welk bedrijf heb je het onderdeel gekocht?",
                    "confirm": "Bevestig uw aanvraag",
                    "construction": "Bouw",
                    "contact": "Contact Details",
                    "copy": "Een kopie van uw verzoek wordt ter referentie aan u verstuurd per email.",
                    "createAccount": "Maak een account aan",
                    "description": "Wanneer je alle gevraagde onderdelen of een deel van de aanvraag aan de klant gaat leveren en er is een overeenkomst kan je de deal sluiten. De aanvrager krijgt vervolgens een bestelbevestiging. Betaling en facturatie geschiet echter buiten Auto Onderdelen Site.",
                    "directionIndicator": "Richtingaanwijzer",
                    "dontHaveThisPart": "Ik heb dit onderdeel niet",
                    "doors": "Deuren",
                    "drive": "Rijden",
                    "email": "E-mail",
                    "explanation": "toelichting (Optional)",
                    "fuelType": "Brandstoftype",
                    "haveThisPart": "Ik heb dit onderdeel",
                    "image": "Ondersteunende extensies: jpg, png. Maximale bestandgrootte 4MB.",
                    "job": "Werk in de auto-industrie",
                    "maxSize": "Afbeeldingen maximaal 2 MB",
                    "message": "Bericht",
                    "mobile": "Mobiel",
                    "part": "Onderdeel",
                    "parts": "Onderdelen",
                    "selectedParts": "Geselecteerde onderdelen",
                    "productID": "Product ID",
                    "partsSold": "Onderdelen verkocht",
                    "password": "Wachtwoord: het wachtwoord dat u hebt gekozen",
                    "phone": "Telefoon",
                    "power": "Macht",
                    "price": "Welke prijs heb je voor dit onderdeel afgesproken?",
                    "requested": "Gevraagde onderdeel (en)",
                    "response": "Ontvang reacties via",
                    "shockAbsorber": "Schokbreker",
                    "save": "Bevestigen",
                    "step1": "Stap 1",
                    "step2": "Stap 2",
                    "step3": "Stap 3",
                    "selectFile": "Selecteer bestand",
                    "send": "Versturen",
                    "selectCatergory": "Select Categorie",
                    "noOptions": "Type om een onderdeel te zoeken",
                    "selectCatergoryDropdown": "Categorie (automatisch aangevuld)",
                    "noResult": "Niets gevonden gebruik een andere zoekterm",
                    "selectSubCatergoryDropdown": "Onderdeelnaam",
                    "selectEmptyListDropdown": "Selecteer eerst een categorie",
                    "selectEmptyResponse": "Geen resultaten gevonden",
                    "selectLabel": "Klik om te selecteren",
                    "selectOption": "Selecteer optie(s)",
                    "deselectLabel": "Klik om te deselecteren",
                    "subCategory": "Select Subcategorieën",
                    "totalAmount": "Totaalbedrag te leveren onderdelen",
                    "size": "Je foto is ",
                    "denySize": "MB dan 4MB. Upload een kleinere foto.",
                    "sizeSuccess": "Je foto is succesvol geupload.",
                    "uploaded": "De geüploade afbeeldingsgrootte is"
                },
                "listings": {
                    "modelPhoto": "Model foto",
                    "make": "Merk",
                    "invalide_code": "Ongeldige afmeldcode",
                    "model": "Model",
                    "no_car": "Er is geen auto gevonden. Druk op de knop om een ​​nieuwe auto toe te voegen",
                    "no_listings": "Er zijn geen vermeldingen gevonden",
                    "performance": "Uitvoering",
                    "year": "Bouwjaar",
                    "marge": "(marge)",
                    "month": "Maand",
                    "date": "Datum",
                    "part": "Onderdeel",
                    "region": "Regio",
                    "photo": "Foto",
                    "addCarPart": "Auto-Onderdeel toevoegen",
                    "addMultipleCarParts": "Meerdere Auto-Onderdelen toevoegen",
                    "printMultipleQRCodes": "Print zojuist aangemaakte onderdelen",
                    "addDonorCar": "Donor Auto toevoegen",
                    "add": "toevoegen",
                    "donorCar": "Donor auto",
                    "instruction": "Instructie",
                    "video": " video's",
                    "teamviewer": "Teamviewer",
                    "help": "Contactformulier",
                    "no_account": "Het account is verwijderd",
                    "settings": "Instellingen",
                    "licensePlate": "Kenteken",
                    "purchase": "Aankoopdatum",
                    "action": "Actie",
                    "delete": "Delete",
                    "delete_message": "Onderdeel succesvol verwijderd.",
                    "results": "Resultaten gevonden",
                    "grid": "Tegel",
                    "list": "Lijst",
                    "market": "Doorzoek onderdelen",
                    "search": "Zoeken",
                    "recent": "Nieuwste",
                    "low_high": "prijs laag naar hoog",
                    "high_low": "prijs hoog naar laag",
                    "near": "Eerst het eerst",
                    "categories": "Categorieën",
                    "category": "Categorie",
                    "posted": "Geplaatst op",
                    "by": "Geplaatst door",
                    "own": "Eigen aanvragen",
                    "other": "Overzicht aanvragen",
                    "clear": "Wissen",
                    "perPage": "Per Pagina",
                    "requester": "Aanvrager",
                    "status": "Status",
                    "actions": "Acties",
                    "supplier": "Aanbieder",
                    "new": "Nieuwe aanvragen",
                    "dashboard": "Dashboard",
                    "city": "Locatie",
                    "stock": "Voorraad",
                    "id": "ID",
                    "aldoc_model": "Aldoc Model",
                    "state": "Staat",
                    "qr_code": "Genereer QR-code",
                    "submit_new": "Opslaan en nieuwe toevoegen",
                    "submit_save": "Opslaan en sluiten",
                    "save": "Opslaan",
                    "success_part": "Onderdeel is succesvol aangemaakt",
                    "success_parts": "Onderdelen zijn succesvol aangemaakt",
                    "open": "Open",
                    "complete": "Gesloten",
                    "to_car": "Aan Auto",
                    "options": "Opties",
                    "goback": "Ga terug",
                    "edit": "Bewerken",
                    "delete_button_part": "Verwijderen",
                    "delete_button_request": "Afmelden",
                    "success_mail_request": "Je reactie op de onderdeel aanvraag is succesvol verzonden naar de aanvrager.",
                    "or": "Of",
                    "decrease_stock": "Voorraad aanpassen",
                    "subtract_stock": "Verminder met &eacute;&eacuten stuk",
                    "upload_pictures": "Foto's uploaden",
                    "toggle_filters": "Filters in-/uitschakelen"
                },
                "help": {
                    "question": "Hulp nodig? 085 - 222 18 97"
                },
                "overview": {
                    "part_name": "Onderdeel naam",
                    "description": "Omschrijving",
                    "overview": "Overzicht",
                    "information": "Informatie product",
                    "price": "Prijs",
                    "construction_year": "Bouwjaar",
                    "make": "Merk",
                    "Model": "Model",
                    "Type": "Motor type",
                    "year": "Bouwjaar",
                    "art_num": "Artikelnummer",
                    "category": "Categorie",
                    "ship_cost": "Verzendkosten",
                    "deposit": "Statiegeld",
                    "garantie_months": "Garantie maanden",
                    "garantie_description": "Garantie opmerking",
                    "condition": "Staat",
                    "location": "Locatie",
                    "power": "Vermogen: ",
                    "particularity_one": "Bijzonderheid 1",
                    "particularity_two": "Bijzonderheid 2"
                },
                "companies": {
                    "stock": "Onderdelen voorraad",
                    "moreInfoRequest": "Bekijk aanvraag",
                    "moreInfo": "Meer informatie",
                    "goTo": "Ga naar website",
                    "demontage": "Demontage",
                    "results": "Bedrijven gevonden",
                    "search": "Type om te zoeken",
                    "clear": "Legen",
                    "name": "Naam *",
                    "company": "Bedrijfsnaam *",
                    "phone": "Telefoon *",
                    "email": "E-mailadres *",
                    "message": "Plaats hier je opmerking",
                    "contact": "Neem contact op met",
                    "back": "Terug naar overzicht",
                    "contact_via": "Neem  contact op met deze aanbieder via:",
                    "more_info": "Voor meer informatie:"
                },
                "filters": {
                    "companyType": "Type bedrijf",
                    "dismantling": "Demontagebedrijven",
                    "garage": "Garagebedrijven",
                    "revision": "Revisiebedrijven",
                    "subscription": "Abonnement",
                    "country": "Land",
                    "companyName": "Naam bedrijf",
                    "postcode": "Postcode",
                    "ray": "Straal",
                    "clear": "Filters verwijderen",
                    "update": "Resultaten bijwerken"
                },
                "catalogue": {
                    "title": "Zoek je auto-onderdeel",
                    "fill": "Geen kenteken ingevuld",
                    "licensePlate": "KENTEKEN",
                    "brand": "MERK",
                    "articleNumber": "ARTIKELNUMMER",
                    "gearboxCode": "VERSNELLINGSBAKCODE",
                    "engineCode": "MOTORCODE",
                    "enterTheLicensePlate": "Voer het kenteken in",
                    "selectcarbrand": "Selecteer het automerk",
                    "findResults": "Vind resultaten",
                    "search": "Zoeken",
                    "searchby": "Gezocht op kenteken ",
                    "selectBrandModel": "Selecteer het Alfa Romeo Giulietta auto-onderdeel dat je zoekt",
                    "description": " Hieronder kun je zoeken op verschillende manieren.\n            Zoek je op kenteken dan vind je alle onderdelen voor die betreffende auto. Heb je geen kenteken?\n            Zoek dan specifiek op een onderdeel via bijvoorbeeld het merk, type of artikelnummer."
                },
                "submissions": {
                    "message_sent": "<p>Uw bericht is succesvol verstuurd</p>"
                },
                "placeholders": {
                    "locale": "Select your language",
                    "timezone": "Select your timezone"
                },
                "brands": {
                    "more": "Bekijk alle merken"
                },
                "cms": {
                    "published_at": "Published at {date}",
                    "published_at_with_owner_linkable": "Published at {date} by <a href=\"{link}\">{name}</a>",
                    "tags": "Tags"
                }
            }
        },
        "permissions": {
            "access": {
                "backend": {
                    "display_name": "Backend toegang",
                    "description": "Heeft toegang tot backend paginas."
                }
            },
            "categories": {
                "access": "Toegang",
                "catalog": "Catalogus",
                "categories": "Categorieën",
                "cms": "Content",
                "companies": "Bedrijven",
                "content": "Content",
                "form": "Forms",
                "listing": "Listing"
            },
            "create": {
                "cars": {
                    "display_name": "Create cars",
                    "description": "Can create cars."
                },
                "categories": {
                    "display_name": "Create categories",
                    "description": "Can create categories."
                },
                "companies": {
                    "display_name": "Create companies",
                    "description": "Can create companies."
                },
                "forms": {
                    "display_name": "Create forms",
                    "description": "Can create forms."
                },
                "parts": {
                    "display_name": "Create parts",
                    "description": "Can create parts."
                },
                "roles": {
                    "display_name": "Create roles",
                    "description": "Can create roles."
                },
                "users": {
                    "display_name": "Create users",
                    "description": "Can create users."
                }
            },
            "delete": {
                "cars": {
                    "display_name": "Delete cars",
                    "description": "Can delete cars."
                },
                "categories": {
                    "display_name": "Delete categories",
                    "description": "Can delete categories."
                },
                "companies": {
                    "display_name": "Delete companies",
                    "description": "Can delete companies."
                },
                "forms": {
                    "display_name": "Delete forms",
                    "description": "Can delete forms."
                },
                "form_submissions": {
                    "display_name": "Delete form submissions",
                    "description": "Can delete form submissions."
                },
                "parts": {
                    "display_name": "Delete parts",
                    "description": "Can delete parts."
                },
                "roles": {
                    "display_name": "Delete roles",
                    "description": "Can delete roles."
                },
                "users": {
                    "display_name": "Delete users",
                    "description": "Can delete users."
                }
            },
            "disable": {
                "listing": {
                    "display_name": "Disable listing",
                    "description": "Can disable listing."
                }
            },
            "edit": {
                "cars": {
                    "display_name": "Edit cars",
                    "description": "Kan voertuigen bewerken."
                },
                "categories": {
                    "display_name": "Categorieën bewerken",
                    "description": "Kan categorieën bewerken."
                },
                "companies": {
                    "display_name": "Bedrijven bewerken",
                    "description": "Kan bedrijven bewerken."
                },
                "forms": {
                    "display_name": "Formulieren bewerken",
                    "description": "Kan formulieren bewerken."
                },
                "listing": {
                    "display_name": "Edit listing",
                    "description": "Can edit listing."
                },
                "parts": {
                    "display_name": "Edit all parts",
                    "description": "Can edit all parts."
                },
                "roles": {
                    "display_name": "Edit roles",
                    "description": "Can edit roles."
                },
                "users": {
                    "display_name": "Edit users",
                    "description": "Can edit users."
                }
            },
            "publish": {
                "listing": {
                    "display_name": "Publish listing",
                    "description": "Can publish listing."
                }
            },
            "unpublish": {
                "listing": {
                    "display_name": "Unpublish listing",
                    "description": "Can unpublish listing."
                }
            },
            "impersonate": {
                "display_name": "Impersonate user",
                "description": "Can take ownership of others user identities. Useful for tests."
            },
            "view": {
                "cars": {
                    "display_name": "View cars",
                    "description": "Can view cars."
                },
                "categories": {
                    "display_name": "Categorieën weergeven",
                    "description": "Kan categorieën inzien."
                },
                "companies": {
                    "display_name": "Bedrijven weergeven",
                    "description": "Kan bedrijven inzien."
                },
                "forms": {
                    "display_name": "View forms",
                    "description": "Can view forms."
                },
                "form_submissions": {
                    "display_name": "View form submissions",
                    "description": "Can view form submissions."
                },
                "parts": {
                    "display_name": "View all parts",
                    "description": "Can view all parts."
                },
                "roles": {
                    "display_name": "Rollen weergeven",
                    "description": "Kan rollen inzien."
                },
                "users": {
                    "display_name": "Gebruikers weergeven",
                    "description": "Kan gebruikers inzien."
                }
            }
        },
        "passwords": {
            "password": "Wachtwoorden moeten minimaal zes tekens bevatten en overeenkomen met de bevestiging.",
            "reset": "Uw wachtwoord is succesvol gereset",
            "sent": "We hebben een link gezonden om je wachtwoord opnieuw in te stellen!",
            "token": "dit wachtwoord-reset-token is ongeldig",
            "user": "Er bestaat geen gebruiker met dit e-mailadres."
        },
        "buttons": {
            "apply": "Toepassen",
            "back": "Terug",
            "cancel": "Annuleer",
            "close": "Sluit",
            "confirm": "Bevestig",
            "create": "Aanmaken",
            "delete": "Verwijder",
            "edit": "Edit",
            "login-as": "Inloggen als {name}",
            "preview": "Preview",
            "save": "Opslaan",
            "send": "Verstuur",
            "show": "Show",
            "update": "Update",
            "view": "View",
            "companies": {
                "create": "Bedrijf aanmaken"
            },
            "forms": {
                "create": "Formulier aanmaken"
            },
            "metas": {
                "create": "Meta aanmaken"
            },
            "plans": {
                "main": "Plans",
                "create": "Create plan"
            },
            "posts": {
                "create": "Post aanmaken",
                "save_and_publish": "Opslaan en publiceren",
                "save_as_draft": "Opslaan als concept"
            },
            "redirects": {
                "create": "Doorverwijzing aanmaken",
                "import": "CSV importeren"
            },
            "roles": {
                "create": "Rol aanmaken"
            },
            "subscriptions": {
                "create": "Abonnement aanmaken"
            },
            "users": {
                "create": "Gebruiker aanmaken"
            },
            "grouped_models": {
                "create": "Grouped model aanmaken"
            }
        },
        "exceptions": {
            "general": "Server fout",
            "unauthorized": "Action not allowed",
            "backend": {
                "users": {
                    "create": "Fout tijdens aanmaken gebruiker",
                    "update": "Fout tijdens bijwerken gebruiker",
                    "delete": "Fout tijdens verwijderen gebruiker",
                    "user_cannot_be_edited": "You cannot edit super admin user",
                    "user_cannot_be_disabled": "Deze gebruiker kan niet worden uitgeschakeld.",
                    "user_cannot_be_destroyed": "Deze gebruiker kan niet worden verwijderd",
                    "user_cannot_be_impersonated": "Er kan niet worden ingelogd als deze gebruiker",
                    "cannot_set_superior_roles": "You cannot attribute roles superior to yours"
                },
                "roles": {
                    "create": "Error on role creation",
                    "update": "Error on role updating",
                    "delete": "Error on role deletion"
                },
                "metas": {
                    "create": "Error on meta creation",
                    "update": "Error on meta updating",
                    "delete": "Error on meta deletion",
                    "already_exist": "There is already a meta for this locale route"
                },
                "form_submissions": {
                    "create": "Error on submission creation",
                    "delete": "Error on submission deletion"
                },
                "forms": {
                    "create": "Error on form creation",
                    "update": "Error on form updating",
                    "delete": "Error on form deletion",
                    "already_exist": "There is already a setting linked to this form"
                },
                "redirects": {
                    "create": "Error on redirect creation",
                    "update": "Error on redirect updating",
                    "delete": "Error on redirect deletion",
                    "already_exist": "There is already a redirect for this path"
                },
                "posts": {
                    "create": "Error on post creation",
                    "update": "Error on post updating",
                    "save": "Error on post saving",
                    "delete": "Error on post deletion"
                }
            },
            "frontend": {
                "user": {
                    "email_taken": "That e-mail address is already taken.",
                    "password_mismatch": "That is not your old password.",
                    "delete_account": "Error on account deletion.",
                    "updating_disabled": "Account editing is disabled."
                },
                "auth": {
                    "registration_disabled": "Registration is disabled."
                }
            }
        },
        "logs": {
            "backend": {
                "users": {
                    "created": "User ID {user} created",
                    "updated": "User ID {user} updated",
                    "deleted": "User ID {user} deleted"
                },
                "form_submissions": {
                    "created": "Form submission ID {form_submission} created"
                }
            },
            "frontend": []
        },
        "mails": {
            "layout": {
                "hello": "Beste AutoOnderdelenSite gebruiker,",
                "regards": "Met vriendelijke groet",
                "trouble": "If you’re having trouble clicking the {action} button, copy and paste the URL below into your web browser :",
                "all_rights_reserved": "All rights reserved.",
                "website": "AutoOnderdelenSite",
                "description": "   AutoOnderdelenSite is een online platform voor het vinden van de juiste koper/verkoper van gebruikte auto-\n                    onderdelen. Wij hebben uitsluitend een bemiddelende functie en zijn geen partij bij een eventuele overeenkomst\n                    tussen de koper en verkoper. Wij aanvaarden geen enkele aansprakelijkheid, uit welken hoofde dan ook. Dit\n                    geldt onder andere doch niet uitsluitend voor de juistheid van de informatie, de levering, de leveringstermijn,\n                    prijzen en garantievoorwaarden van onderdelen, aangeboden door op ons platform aangesloten auto-\n                    demontagebedrijven.\n                    De garantie- en leveringsvoorwaarden, alsmede verzendkosten kunnen per onderdeel en per bedrijf verschillen.\n                    De prijzen van de aangeboden auto-onderdelen kunnen sterk van elkaar verschillen. Wij adviseren u hiernaar\n                    vooraf te informeren bij de verkoper."
            },
            "password_reset": {
                "subject": "Wachtwoord reset",
                "intro": "Hierbij de link om jouw wachtwoord te wijzigen: ",
                "website": "Volg de instructies via de website. ",
                "action": "Reset Password",
                "outro": "Werkt bovenstaande button niet? Kopieer en plak dan deze URL in je webbrowser:",
                "contact": "Heb je nog vragen? Vul het ",
                "in": " in."
            },
            "contact": {
                "subject": "Nieuwe contractaanvraag",
                "copy": "Kopie van uw reactie",
                "new_contact": "Er is een nieuw bericht binnengekomen via de website.",
                "greetings": "Beste",
                "reaction": "Er is een reactie op je onderdeel aanvraag:",
                "content": "Dit is het bericht van: ",
                "contact": "CONTACTGEGEVENS AANBIEDER",
                "offer": "AANBOD",
                "door": "Deuren",
                "requestid": "Aanvraag #",
                "link": "Heb je nog vragen? Neem contact op via het",
                "salutation": "Met vriendelijke groet,",
                "app": "AutoOnderdelenSite",
                "category": "Categorie: ",
                "carInfo": "JOUW OPGEGEVEN VOERTUIGGEGEVENS",
                "email": "E-mailadres:",
                "phone": "Telefoonnummer: ",
                "company": "Bedrijfsnaam: ",
                "address": "Adres: ",
                "info": "Neem middels bovenstaande gegevens verder contact op voor meer informatie of omtrent de eventuele aankoop van het onderdeel."
            },
            "alert": {
                "subject": "[{app_name}] Exception error",
                "message": "You've got unexpected server exception error which message is : {message}.",
                "trace": "All trace detail :"
            },
            "confirmation": {
                "request": "Jouw aanvraag is bij ons binnengekomen. Wij gaan er zo snel mogelijk mee aan de slag.",
                "data": "JOUW OPGEGEVEN VOERTUIGGEGEVENS",
                "lp": "Kenteken:",
                "model": "Model:",
                "type": "Type:",
                "year": "Bouwjaar:",
                "cylinder": "Cilinderinhoud:",
                "parts": "JOUW AANVRAAGDE ONDERDELEN",
                "category": "Categorie",
                "description": "Beschrijving",
                "code": "Afmeldcode",
                "contact": "JOUW CONTACTGEGEVENS",
                "name": "Naam:",
                "place": "Plaats:",
                "form": "contactformulier. "
            },
            "activation": {
                "subject": "Activatielink account AutoOnderdelenSite.nl",
                "activate": "Jouw account kan je activeren door op onderstaande link te klikken: ",
                "contact": "Werkt dat niet, kopieer onderstaande link in jouw eigen browser: ",
                "link": "Activeringslink",
                "salutation": "Beste gebruiker",
                "content": "Dank voor jouw registratie bij AutoOnderdelenSite.nl. Voordat je kan inloggen moet\n                        jouw account nog geactiveerd worden."
            },
            "account": {
                "verify": {
                    "subject": "Activatielink account AutoOnderdelenSite.nl"
                }
            },
            "listing": {
                "reminder": {
                    "subject": "Herinnering onderdelenaanvraag {sitename}"
                }
            },
            "request": {
                "confirmation": {
                    "subject": "Onderdelen aanvraag via AutoOnderdelensite.nl"
                }
            }
        },
        "pagination": {
            "previous": "&laquo; Previous",
            "next": "Next &raquo;"
        },
        "listings": {
            "back_to": "Terug naar advertenties",
            "back_to_my": "Terug naar mijn advertenties",
            "listing": "Advertentie",
            "listings": "Advertenties",
            "results": "{make} {model} auto-onderdelen",
            "no_results": "Geen resultaten gevonden.",
            "search_type": {
                "article_number": "artikelnummer",
                "gearbox_code": "versnellingsbakcode",
                "engine_code": "motorcode"
            },
            "new_search": "Nieuwe zoekopdracht"
        },
        "subscriptions": {
            "subscribe_to_plan": "Abonneer je op een abonnement"
        },
        "alerts": {
            "backend": {
                "actions": {
                    "invalid": "Ongeldige actie"
                },
                "companies": {
                    "created": "Bedrijf aangemaakt",
                    "updated": "Bedrijf bijgewerkt",
                    "deleted": "Bedrijf verwijderd"
                },
                "forms": {
                    "created": "Formulier aangemaakt",
                    "deleted": "Formulier verwijderd",
                    "updated": "Formulier bijgewerkt"
                },
                "form_submissions": {
                    "deleted": "Inzending verwijderd",
                    "bulk_destroyed": "Geselecteerde inzendingen verwijderd"
                },
                "posts": {
                    "created": "Post aangemaakt",
                    "updated": "Post bijgewerkt",
                    "deleted": "Post verwijderd",
                    "bulk_destroyed": "Geselecteerde posts verwijderd",
                    "bulk_published": "Geselecteerde posts published",
                    "bulk_pending": "Geselecteerde posts are awaiting moderation",
                    "bulk_pinned": "Geselecteerde posts pinned",
                    "bulk_promoted": "Geselecteerde posts promoted"
                },
                "metas": {
                    "created": "Meta aangemaakt",
                    "updated": "Meta bijgewerkt",
                    "deleted": "Meta verwijderd",
                    "bulk_destroyed": "Geselecteerde metas verwijderd"
                },
                "redirects": {
                    "created": "Doorverwijzing aangemaakt",
                    "updated": "Doorverwijzing bijgewerkt",
                    "deleted": "Doorverwijzing verwijderd",
                    "bulk_destroyed": "Geselecteerde redirects verwijderd",
                    "bulk_enabled": "Geselecteerde redirects enabled",
                    "bulk_disabled": "Geselecteerde redirects disabled",
                    "file_imported": "File successfully imported"
                },
                "roles": {
                    "created": "Rol aangemaakt",
                    "updated": "Rol bijgewerkt",
                    "deleted": "Rol verwijderd"
                },
                "users": {
                    "created": "Gebruiker aangemaakt",
                    "updated": "Gebruiker bijgewerkt",
                    "deleted": "Gebruiker verwijderd",
                    "bulk_destroyed": "Geselecteerde gebruikers verwijderd",
                    "bulk_enabled": "Geselecteerde gebruikers geactiveerd",
                    "bulk_disabled": "Geselecteerde gebruikers gedeactiveerd"
                }
            },
            "frontend": {
                "listings": {
                    "stock_updated": "De voorraad is succesvol aangepast",
                    "photos_updated": "De foto's zijn succesvol geüpload"
                }
            }
        }
    }
}
