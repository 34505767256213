import axios from 'axios'

export default {
  props: {
    stock: {
      type: Number,
      required: true
    },
    listing: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      s: this.stock,
      i: [],
      successful: {
        s: false,
        i: false
      }
    }
  },
  methods: {
    decreaseWithOne: function() {
      this.s = this.s - 1
      this.updateStock()
    },
    updateStock: function() {
      let form = {
        stock: this.s
      }

      axios
        .post(this.$app.route('frontend.listing.stock', this.listing), form)
        .then(response => {
          this.s = response.data.stock
          this.successful.s = true
        })
    },
    uploadPhotos: function() {
      let form = new FormData()

      this.i.map((file, key) => {
        form.append(`image[${key}]`, file)
      })

      let config = {
        header: {
          'content-Type': 'multipart/form-data'
        }
      }

      axios
        .post(
          this.$app.route('frontend.listing.photos', this.listing),
          form,
          config
        )
        .then(response => {
          this.successful.i = true
        })
    },
    changeImage: function(e, n) {
      let reader = new FileReader(),
        image = e.target.files[0]

      this.i[n - 1] = image

      reader.onload = () => {
        let output = document.getElementById(`preview${n}`)
        output.src = reader.result
      }
      reader.readAsDataURL(e.target.files[0])
    },
    reload: function() {
      window.location.reload()
    }
  }
}
