<template>
  <div class="row mt-3" id="build_date">
    <label for="year" class="col-lg-2">
      {{ $t('labels.frontend.listings.year') }} *
    </label>
    <div class="col-lg-4">
      <div class="row d-flex justify-content-between">
        <div class="col-lg-6">
          <select
            name="build_year"
            id="build_year"
            class="form-control br-r-4"
            v-model="year"
          >
            <option :value="null" selected disabled>-</option>
            <option v-for="item in years" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
        <div class="col-lg-6 full-width d-flex justify-content-end p-left-6">
          <select
            name="build_month"
            id="build_month"
            class="form-control br-r-4"
            v-model="month"
          >
            <option :value="null" selected disabled>-</option>
            <option v-for="item in months" :key="item" :value="item">
              {{ item }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectYear: {
      type: [Number, null],
      required: false,
      default: null
    },
    selectMonth: {
      type: [Number, null],
      required: false,
      default: null
    }
  },
  data() {
    return {
      year: this.selectYear,
      month: this.selectMonth
    }
  },
  computed: {
    years: function() {
      let date = new Date().getFullYear()
      let years = []
      for (let i = 0; i <= 50; i++) {
        years.push(date - i)
      }
      return years
    },
    months: function() {
      let months = []
      for (let i = 1; i <= 12; i++) {
        months.push(i)
      }
      return months
    }
  }
}
</script>
