<template slot-scope="row">
  <div data-turbolinks="false">
    <b-form-group label-cols-sm="3" class="mt-2 mr-2">
      <b-input-group>
        <b-form-input v-model="filter" placeholder="Vul je zoekterm in">
        </b-form-input>
        <b-input-group-append>
          <button
            :disabled="!filter"
            @click="filter = ''"
            class="blue-light-bg white-text-color btn-size-color ml-2 font-montserrat-regular"
          >
            Zoeken
          </button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <!-- eslint-disable -->
    <b-table
      selectable
      select-mode="single"
      striped
      hover
      :items="items"
      :fields="fields"
      :filter="filter"
      :current-page="currentPage"
      @row-selected="editRow"
      :per-page="perPage"
      :outlined="outlined"
      responsive
      autoWidth
      borderless
    >
      <!-- eslint-disable -->
      <template v-slot:cell(id)="row">
        P{{ row.item.id }}
      </template>
      <template v-slot:cell(part)="row">
        <em v-if="row.item.isConcept">(concept)</em>
        {{ row.item.part }}
      </template>
      <template v-slot:cell(thumbnail)="row">
        <b-img thumbnail fluid v-bind="mainThumbProps" :src="row.item.thumbnail"></b-img>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          @click="publish(row.item)"
          pill :variant="row.item.variant"
          v-if="row.item.state.match($i18n.t('labels.frontend.listings.open'))"
        >
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </b-button>
        <b-button
          pill variant="outline-primary"
          @click="printQR(row.item)"
        >
          <i class="fa fa-qrcode" aria-hidden="true"></i>
        </b-button>
        <b-button
          size="sm"
          @click="fireDelete(row.item.id, row.index)"
          class="mr-1"
          style="padding: 8px 13px;"
          pill variant="outline-primary"
          id="delete"
        >
          <i class="fa fa-trash-o" aria-hidden="true">
          </i>
        </b-button>
      </template>
    </b-table>
    <b-row>
      <b-col md="10" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
      <b-col md="2" class="my-1 mb-2">
        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Bus from '../../eventbus'

let QRCode = require('qrcode')

export default {
  props: {
    mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 200, 500],
      outlined: true,
      mainThumbProps: { width: 60, height: 60 },
      fields: [
        {
          key: 'id',
          label: this.$i18n.t('labels.frontend.listings.id'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'make',
          label: this.$i18n.t('labels.frontend.listings.make'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'type',
          label: this.$i18n.t('labels.frontend.listings.performance'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'year',
          label: this.$i18n.t('labels.frontend.listings.year'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'part',
          label: this.$i18n.t('labels.frontend.listings.part'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'thumbnail',
          label: this.$i18n.t('labels.frontend.listings.photo'),
          sortable: false,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'time',
          label: this.$i18n.t('labels.frontend.listings.date'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'location',
          label: this.$i18n.t('labels.frontend.listings.city'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'stock',
          label: this.$i18n.t('labels.frontend.listings.stock'),
          sortable: true,
          thClass: 'background-gray',
          tdClass: 'black-color'
        },
        {
          key: 'actions',
          label: this.$i18n.t('labels.frontend.listings.actions'),
          sortable: false,
          thClass: 'background-gray actions',
          tdClass: 'black-color'
        }
      ],
      items: [],
      durl: this.$app.route('account.api.listings')
    }
  },
  mounted() {
    if (this.mode === 'requests') {
      this.durl = this.$app.route('account.api.listings.requests')
    } else if (this.mode === 'others') {
      this.durl = this.$app.route('account.api.listings.requests.others')
    }

    axios.get(this.durl).then(res => {
      let listings = []
      for (let i in res.data.listings) listings.push(res.data.listings[i])
      listings = listings.map(item => {
        if (item.isPublished) {
          item.variant = 'outline-success'
        } else {
          item.variant = 'outline-danger'
        }
        return item
      })
      this.items = listings.reverse()
      this.totalRows = this.items.length
    })

    Bus.$on('printMultipleQR', () => {
      this.printMultipleQR()
    })
  },
  methods: {
    editRow(items) {
      let item = items[0]
      window.location.href = `/account/listings/${item.rk}/edit`
    },
    select() {
      this.selected = []
      if (!this.selectAll) {
        for (let i in this.items) {
          this.selected.push(this.items.i.ID)
        }
      }
    },
    fireDelete(id, index) {
      axios
        .delete('/account/listings/' + this.items[index].rk)
        .then(response => this.items.splice(index, 1))
      window.location.reload()
    },
    printQR(item) {
      let make = item.make.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
      let model = item.type.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
      let qrdata =
        this.$app.route('frontend.listing.show', {
          listing_trashed: item.rk,
          make: make,
          model: model,
          slug: item.slug,
          id: item.id
        }) + ' '

      let date = item.time.substr(0, item.time.indexOf(' '))
      let location = item.location !== null ? item.location + ' ' : ''
      let company = item.co !== null ? item.co : ''

      let txt = 'P' + item.id + ' ' + item.part + '<br \/>'
      txt += item.make + ' ' + item.type + ' ' + item.year + '<br \/>'
      txt += item.sku + ' ' + date + '<br \/>'
      txt += location + company + '<br \/>'

      let engineType =
        item.engineType !== null ? 'Motortype: ' + item.engineType : ''
      let fuel = item.fuel !== null ? 'Brandstof: ' + item.fuel : ''
      let engineCode =
        item.engineCode !== null ? 'Motorcode: ' + item.engineCode : ''
      let mileage = item.mileage !== null ? 'Km-stand: ' + item.mileage : ''
      let description = item.description !== '' ? item.description : ''

      let subtxt = engineType + ' ' + fuel + '<br \/>'
      subtxt += engineCode + ' ' + mileage + '<br \/>'
      subtxt += description

      const label = window.open(
        '',
        '',
        'left=0,top=0,width=378,height=189,toolbar=0,scrollbars=0,status=0'
      )
      label.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title> </title>
  </head>
  <body style="margin:14px">
    <table style="border:0;width:100%; page-break-after: always">
      <tr style="vertical-align: top;">
        <td style="width:120px"><canvas id="canvas"></canvas></td>
        <td style="padding-left: 10px; font-family: Arial, sans-serif; font-size: 18px; line-height: 1.2; font-weight:700">
          ${txt}
        </td>
      </tr>
      <tr>
        <td colspan="2" style="padding-top: 10px; font-family: Arial, sans-serif; font-size: 14px; line-height: 1.2;">
          ${subtxt}
        </td>
      </tr>
    </table>
  </body>
</html>`)

      let canvas = label.document.getElementById('canvas')
      QRCode.toCanvas(canvas, qrdata, {
        margin: 0,
        width: 90
      })

      label.document.close()
      label.focus()
      label.print()
      label.close()
    },
    printMultipleQR() {
      let savedItems = CarParts.saved_items

      if (!savedItems) {
        return
      }

      const label = window.open(
        '',
        '',
        'left=0,top=0,width=378,height=189,toolbar=0,scrollbars=0,status=0'
      )

      let items = ''
      this.items.map(item => {
        if (!savedItems.includes(item.rk)) {
          return
        }
        let date = item.time.substr(0, item.time.indexOf(' '))
        let company = item.co !== null ? item.co : ''

        let make = item.make.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
        let model = item.type.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
        let qrdata =
          this.$app.route('frontend.listing.show', {
            listing_trashed: item.rk,
            make: make,
            model: model,
            slug: item.slug,
            id: item.id
          }) + ' '
        let txt = 'P' + item.id + ' ' + item.part + '<br \/>'
        txt += item.make + ' ' + item.type + ' ' + item.year + '<br \/>'
        txt += item.sku + ' ' + date + '<br \/>'
        txt += location + ' ' + company + '<br \/>'

        let engineType =
          item.engineType !== null ? 'Motortype: ' + item.engineType : ''
        let fuel = item.fuel !== null ? 'Brandstof: ' + item.fuel : ''
        let engineCode =
          item.engineCode !== null ? 'Motorcode: ' + item.engineCode : ''
        let mileage = item.mileage !== null ? 'Km-stand: ' + item.mileage : ''
        let description = item.description !== '' ? item.description : ''

        let subtxt = engineType + ' ' + fuel + '<br \/>'
        subtxt += engineCode + ' ' + mileage + '<br \/>'
        subtxt += description

        let html = `
    <table style="border:0;width:100%; page-break-after: always">
      <tr style="vertical-align: top;">
        <td style="width:120px"><canvas id="canvas-${item.id}"></canvas></td>
        <td style="padding-left: 10px; font-family: Arial, sans-serif; font-size: 18px; line-height: 1.2; font-weight:700">
          ${txt}
        </td>
      </tr>
      <tr>
        <td colspan="2" style="padding-top: 10px; font-family: Arial, sans-serif; font-size: 14px; line-height: 1.2;">
          ${subtxt}
        </td>
      </tr>
    </table>
        `

        items += html
      })

      label.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title> </title>
  </head>
  <body style="margin:14px">
    ${items}
  </body>
</html>`)

      this.items.map(item => {
        if (!savedItems.includes(item.rk)) {
          return
        }
        let make = item.make.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
        let model = item.type.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
        let qrdata =
          this.$app.route('frontend.listing.show', {
            listing_trashed: item.rk,
            make: make,
            model: model,
            slug: item.slug,
            id: item.id
          }) + ' '
        let canvas = label.document.getElementById(`canvas-${item.id}`)
        QRCode.toCanvas(canvas, qrdata, {
          margin: 0,
          width: 90
        })
      })

      label.document.close()
      label.focus()
      label.print()
    },
    buildUrl(item) {
      let make = item.make.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
      let model = item.type.replace(/[^A-Z0-9]+/gi, '-').toLowerCase()
      return `/auto-onderdelen-voorraad/onderdeel/merk/${make}/model/${model}/onderdeel/${item.slug}/P${item.id}/${item.rk}`
    },
    publish(item) {
      let url = `/account/api/listings/${item.rk}/toggle`
      axios.post(url).then(res => {
        let change = this.items.filter(listing => listing.id == item.id)[0]
        change.isPublished = res.data.listing.isPublished
        if (change.isPublished) {
          change.variant = 'outline-success'
        } else {
          change.variant = 'outline-danger'
        }
      })
    }
  }
}
</script>
<style module lang="css">
.background-gray {
  background-color: #d8d8d8 !important;
  font-size: 14px;
}
.black-color {
  color: black !important;
  font-size: 13px;
  font-family: Ubuntu;
}
table {
  border-collapse: separate !important;
  border: 0 !important;
}
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: whitesmoke;
  border: none;
}
table thead tr th,
table tbody tr td {
  background-color: #ddebf4;
}
table.border {
  border: 0 !important;
}
.btn-size-color {
  padding: 7px;
  font-size: inherit;
}
</style>
