import { render, staticRenderFns } from "./Cars.vue?vue&type=template&id=ffd8f1dc&slot-scope=row&"
import script from "../../assets/js/cars.js?vue&type=script&lang=js&"
export * from "../../assets/js/cars.js?vue&type=script&lang=js&"
import style0 from "./Cars.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports