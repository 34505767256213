export default {
  name: 'SearchCompaniesTable',
  props: {
    companyName: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  data() {
    return {
      search: this.$i18n.t('labels.frontend.companies.search'),
      selected: false,
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 25],
      outlined: true,
      fields: [{ key: 'name', sortable: true }],
      items: [],
      filterOn: [],
      checked: false,
      type: [],
      filtered: [],
      companyTypeFilter: []
    }
  },
  computed: {
    rows() {
      return Object.keys(this.items).length
    }
  },
  mounted() {
    axios.get('/companies/search').then(response => {
      this.items = response.data
      this.totalRows = this.items.count
      this.companyTypeFilter = this.items.data
    })
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.items.count = filteredItems.length
    },
    filterType(type, counter) {
      let item = this.items
      for (let i = 0; i < item.count; i++) {
        if (item.data[i].type[0] === type || item.data[i].type[1] === type) {
          this.filtered.push(item.data[i])
          counter++
        }
      }
      item.data = this.filtered
      item.count = counter
      this.totalRows = counter
    },
    onCheck(value) {
      let k = 0
      if (value[0] === 'disassembly') {
        this.filterType(value[0], k)
      } else if (value[0] === 'garage') {
        this.filterType(value[0], k)
      } else if (value[0] === 'revision') {
        this.filterType(value[0], k)
      } else {
        axios.get('/companies/search').then(response => {
          this.items = response.data
          this.totalRows = this.items.count
          this.filtered = []
          k = 0
        })
      }
    }
  }
}
