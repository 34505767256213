import { render, staticRenderFns } from "./Part.vue?vue&type=template&id=4ca2e283&"
import script from "../../assets/js/part.js?vue&type=script&lang=js&"
export * from "../../assets/js/part.js?vue&type=script&lang=js&"
import style0 from "../../assets/css/part.css?vue&type=style&index=0&%3Astyle=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports