export default {
  props: {
    part: {
      type: String,
      default: null
    },
    sku: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    image: {
      type: String,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    input: {
      type: Object,
      default: () => ({})
    },
    inputs: {
      type: Array,
      default: () => []
    },
    isShow: {
      type: Boolean,
      default: false
    },
    submitWithForm: {
      type: Boolean,
      default: false
    },
    cat: {
      type: Number,
      default: 0
    },
    name: {
      type: Number,
      default: 0
    },
    attribute_values: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      attributes: [],
      // attribute_values: [],
      test: false,
      isShowin: false,
      category: null,
      categories: [],
      selectedFile: null,
      sizeImage: null,
      subcategory: null,
      subcategories: [],
      url: null,
      cat_key: [],
      cat_value: [],
      searchSubcategory: [],
      isLoading: false,
      selectLabel: this.$i18n.t('labels.frontend.request.selectLabel'),
      deselectLabel: this.$i18n.t('labels.frontend.request.deselectLabel'),
      value: null
    }
  },
  mounted() {
    this.isShowin = this.isShow
    axios.get('/api/categories').then(res => {
      this.categories = res.data
      this.cat_key = Object.keys(res.data)
      this.cat_value = Object.values(res.data)
    })

    if (this.cat && this.name) {
      axios.get(`/api/categories/${this.cat}`).then(res => {
        this.value = {
          parent_id: this.cat,
          id: this.name,
          name: res.data[this.name]
        }
        this.changeCategory(this.value)
      })
    }
  },
  methods: {
    limitText(count) {
      return `and ${count} other subcategories`
    },
    deleteImage() {
      this.url = null
      return (this.input.image = null)
    },
    clearAll() {
      this.value = null
      this.searchSubcategory = []
      this.input.category = null
      this.input.subcategory = null
      this.subcategory = null
    },
    asyncFind(query) {
      if (query.length >= 3) {
        this.isLoading = true
        axios.post('/api/categories', { q: query }).then(response => {
          this.searchSubcategory = response.data
          this.isLoading = false
        })
      }
    },
    onFileChanged(event) {
      this.selectedFile = event.target.files[0]
      let n = this.selectedFile.size / 1000000
      this.sizeImage = n.toFixed(1 - Math.floor(Math.log(n) / Math.log(10)))
      if (this.sizeImage > 4) {
        alert(
          this.$i18n.t('labels.frontend.request.size') +
            this.sizeImage +
            this.$i18n.t('labels.frontend.request.denySize')
        )
        this.selectedFile = null
      } else {
        this.input.image = this.selectedFile
        this.url = URL.createObjectURL(this.selectedFile)
      }
    },
    nameWithLangCat(e) {
      return this.categories[e]
    },
    changeCategory(e) {
      if (this.searchSubcategory !== null) {
        this.input.category = e.parent_id
        this.input.subcategory = JSON.parse(JSON.stringify(e.id))
        this.subcategory = JSON.parse(JSON.stringify(e.id))
        this.getAttributes(this.input.subcategory)
      } else {
        this.clearAll()
      }
    },
    getAttributes(id) {
      axios
        .get(
          this.$app.route('api.attributes', {
            id: id
          })
        )
        .then(res => {
          this.attributes = res.data
        })
    }
  }
}
